import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoutes = () => {
  const PublicRoutesState = useSelector((state) => state.firebaseAuth.user);

  return PublicRoutesState ? <Navigate to="/programs" /> : <Outlet />;
};

export default PublicRoutes;
