import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { db } from "../../config/firebase";
import {
  collection,
  doc,
  getDoc,
  query,
  getDocs,
  where,
  orderBy,
  updateDoc,
} from "firebase/firestore";

export const firebase_GetWorkoutHistory = createAsyncThunk(
  "workoutSlice/firebase_GetWorkoutHistory",
  async (_, ThunkAPI) => {
    const state = ThunkAPI.getState().firebaseAuth;
    if (state.userProfile.currentDay === null) {
      return ThunkAPI.rejectWithValue("No Program Selected");
    }
    try {
      const workoutHistory = [];
      let exercises = [];
      const [
        querySnapshotHistory,
        querySnapshotCurrentDay,
        querySnapshotExercisesNames,
        querySnapshotExercises,
      ] = await Promise.all([
        getDocs(
          query(
            collection(db, "workouts"),
            where("uid", "==", state.user.uid),
            orderBy("date", "desc")
          )
        ),
        getDoc(state.userProfile.currentDay),
        getDocs(query(collection(db, "exercises"))),
        getDocs(
          query(collection(db, state.userProfile.currentDay.path, "exercises"))
        ),
      ]);

      for await (const doc of querySnapshotHistory.docs) {
        workoutHistory.push({
          ...doc.data(),
          id: doc.id,
        });
      }

      for await (const exe of querySnapshotExercises.docs) {
        const exeName =
          querySnapshotExercisesNames.docs[
            querySnapshotExercisesNames.docs.findIndex(
              (exeName) => exeName.id === exe.data()["exercise"].id
            )
          ];
        exercises.push({
          ...exe.data(),
          exerciseName: exeName.data()["exerciseName"],
        });
      }

      const currentDay = {
        ...querySnapshotCurrentDay.data(),
        exercises: exercises.sort(function (a, b) {
          return a.order - b.order;
        }),
        id: querySnapshotCurrentDay.ref,
      };

      return [workoutHistory, currentDay];
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);

export const firebase_SkipDay = createAsyncThunk(
  "workoutSlice/firebase_SkipDay",
  async (_, ThunkAPI) => {
    try {
      const state = ThunkAPI.getState().firebaseAuth;
      const [querySnapshotDays, querySnapshotDayNumber] = await Promise.all([
        getDocs(
          query(
            collection(
              db,
              "programs",
              state.userProfile.currentProgram.id,
              "Days"
            ),
            orderBy("dayNumber", "asc")
          )
        ),

        (await getDoc(state.userProfile.currentDay)).data()["dayNumber"],
      ]);

      await updateDoc(doc(db, "users", state.user.uid), {
        currentDay:
          querySnapshotDays.docs[
            querySnapshotDayNumber % querySnapshotDays.docs.length
          ].ref,
      });

      return true;
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);

export const workoutSlice = createSlice({
  name: "workout",
  initialState: {
    status: "idle",
    workoutHistory: [],
    currentDay: {},
    msg: "",
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.workoutHistory = [];
      state.currentDay = {};
      state.msg = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_GetWorkoutHistory.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_GetWorkoutHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.workoutHistory = action.payload[0];
        state.currentDay = action.payload[1];
      })
      .addCase(firebase_GetWorkoutHistory.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
      })
      .addCase(firebase_SkipDay.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_SkipDay.fulfilled, (state, action) => {
        state.status = "succeeded";
        /* state.currentDay = action.payload; */
      })
      .addCase(firebase_SkipDay.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = workoutSlice.actions;

export default workoutSlice.reducer;
