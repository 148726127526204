import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button, Divider, TextField } from "@mui/material";
import Item from "../../components/itemPaper/Item";
import Stack from "@mui/material/Stack";
import logo from "../../resources/images/adrelien.png";
import {
  firebase_Signup,
  setEmail,
  setPassword,
  reset,
  setPasswordConfirm,
  setUsername,
  usernameAvailable,
} from "./signupSlice";
import { useSelector, useDispatch } from "react-redux";

function Signup(props) {
  const signupState = useSelector((state) => state.signup);
  const dispatch = useDispatch();
  useEffect(() => {
    // I'll run when the component is mounted
    // Setting the initial state for this component
    return () => {
      dispatch(reset());

      // all the clean-ups related to this component
      // I'll run when the component is unmounted
    };
  }, [dispatch]);

  useEffect(() => {
    // I'll run when the component is mounted
    // Setting the initial state for this component
    dispatch(usernameAvailable());
  }, [signupState.username, dispatch]);
  return (
    <div>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <Stack>
        {signupState.msg !== "" ? (
          <Item
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "25px",
            }}
          >
            {signupState.msg}
          </Item>
        ) : null}

        <Item sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          <img alt="Adrelien" src={logo} style={{ margin: "28px" }} />

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "10px",
            }}
            autoComplete="false"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(firebase_Signup());
            }}
          >
            <TextField
              required={true}
              label="E-mail"
              type="email"
              value={signupState.email}
              onChange={(e) => {
                dispatch(setEmail(e.target.value));
              }}
            />
            <TextField
              required={true}
              error={!signupState.usernameAvailable}
              label="FIT Username"
              type="text"
              value={signupState.username}
              onChange={(e) => {
                dispatch(setUsername(e.target.value));
              }}
            />
            <TextField
              required={true}
              label="Password"
              type="password"
              value={signupState.password}
              onChange={(e) => {
                dispatch(setPassword(e.target.value));
              }}
            />
            <TextField
              required={true}
              label="Password Confirm"
              type="password"
              value={signupState.passwordConfirm}
              onChange={(e) => {
                dispatch(setPasswordConfirm(e.target.value));
              }}
            />
            <Button
              disabled={signupState.state === "loading"}
              type="submit"
              variant="outlined"
            >
              Sign Up
            </Button>
          </form>
          <Divider />
          <div>
            You already have an account?{" "}
            <Link to={"../signin"} style={{ color: "inherit" }}>
              Sign In
            </Link>
          </div>
        </Item>
      </Stack>
    </div>
  );
}

export default Signup;
