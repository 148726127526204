import React, { useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Chip,
  CircularProgress,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/navbar";
import Item from "../../components/itemPaper/Item";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import AppBar from "../../components/appBar/appBar";
import { Stack } from "@mui/system";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DialogSlide from "../../components/dialogSlide/dialogSlide";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import {
  ArrowBackIos,
  ArrowForwardIos,
  RemoveOutlined,
} from "@mui/icons-material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ProgramDetails from "./programDetails";

function Programs(props) {
  const programsState = useSelector((state) => state.programs);
  const firebaseAuthState = useSelector((state) => state.firebaseAuth);
  const [openStatus, setOpenStatus] = useState(false);
  const [programID, setProgramID] = useState(null);
  const [programName, setProgramName] = useState(null);

  const [addProgram, SetAddProgram] = useState(false);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Program", "Days", "Exercises", "Finish"];
  const [newProgram, setNewProgram] = useState({ programName: "", days: [{}] });

  return (
    <div>
      <ProgramDetails
        programID={programID}
        programName={programName}
        openStatus={openStatus}
        onClose={() => setOpenStatus(false)}
      />
      <Navbar />

      <Helmet>
        <title>Workout Plans</title>
      </Helmet>
      <AppBar title={"GYM Programs"} subtitle={"Select Your Program"} />
      {programsState.status === "succeeded" ? (
        <Stack>
          <div>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                SetAddProgram(!addProgram);
                setNewProgram({ programName: "", days: [{}] });
                setActiveStep(0);
              }}
            >
              Create Program
            </Button>
          </div>
          {programsState.programs.map((row, index) =>
            row.id === firebaseAuthState.userProfile?.currentProgram?.id ? (
              <div
                key={index}
                onClick={() => {
                  setProgramID(row.id);
                  setProgramName(row.programName);
                  setOpenStatus(true);
                }}
              >
                <Item
                  sx={{
                    backgroundImage:
                      "linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 150%), url(" +
                      row.imgLink +
                      ")",
                    backgroundSize: "cover",
                    height: "200px",
                    flexDirection: "column",
                  }}
                >
                  <Chip
                    label="Active"
                    style={{
                      padding: "12px 8px 8px",
                      fontSize: "12px",
                      fontWeight: "100",
                      position: "right",
                      color: "white",
                      fontFamily: "sans-serif",
                      marginLeft: "70%",
                    }}
                  />

                  <div
                    style={{
                      padding: "100px 8px 8px",
                      fontSize: "22px",
                      fontWeight: "300",
                      textAlign: "left",
                      color: "white",
                      fontFamily: "sans-serif",
                      alignSelf: "flex-start",
                    }}
                  >
                    {row.programName}
                  </div>
                </Item>
              </div>
            ) : null
          )}

          {programsState.programs.map((row, index) => (
            <div
              key={index}
              onClick={() => {
                setProgramID(row.id);
                setProgramName(row.programName);
                setOpenStatus(true);
              }}
            >
              <Item
                sx={{
                  backgroundImage:
                    "linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 150%), url(" +
                    row.imgLink +
                    ")",
                  backgroundSize: "cover",
                  height: "200px",
                  flexDirection: "column-reverse",
                }}
              >
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: "300",
                    color: "white",
                    alignSelf: "flex-start",
                  }}
                >
                  {row.programName}
                </div>
              </Item>
            </div>
          ))}
          <DialogSlide
            openStatus={addProgram}
            onClose={() => {
              SetAddProgram(!addProgram);
              setNewProgram({
                programName: "",
                days: [{ dayName: "", dayNumber: undefined, exercises: [] }],
              });
              setActiveStep(0);
            }}
            title={"New Program"}
            subtitle={"Create Your Program"}
          >
            <Stack sx={{ flexDirection: "column", flex: "1" }} spacing={5}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {activeStep === 0 ? (
                <div>
                  <TextField
                    fullWidth
                    required={true}
                    /*                     error={!signupState.usernameAvailable}
                     */ label="Program Name"
                    type="text"
                    value={newProgram.programName}
                    onChange={(e) => {
                      setNewProgram({
                        ...newProgram,
                        programName: e.target.value,
                        days: [
                          { dayName: "", dayNumber: undefined, exercises: [] },
                        ],
                      });
                    }}
                  />
                  <BottomNavigation
                    sx={{
                      zIndex: "1200",
                      position: "fixed",
                      bottom: 30,
                      left: "5%",
                      right: "5%",
                      borderRadius: "200px",
                      "@media (min-width: 1000px)": {
                        marginLeft: "30%",
                        marginRight: "30%",
                      },
                    }}
                  >
                    <BottomNavigationAction
                      onClick={() => {
                        SetAddProgram(!addProgram);
                        setNewProgram({
                          programName: "",
                          days: [
                            {
                              dayName: "",
                              dayNumber: undefined,
                              exercises: [],
                            },
                          ],
                        });
                        setActiveStep(0);
                      }}
                      icon={<ClearOutlinedIcon />}
                    />

                    {newProgram.programName.length != 0 && (
                      <BottomNavigationAction
                        disabled={newProgram.programName.length === 0}
                        onClick={() => {
                          setActiveStep(activeStep + 1);
                        }}
                        sx={{
                          color: "#d40000",
                        }}
                        icon={<ArrowForwardIos />}
                      />
                    )}
                  </BottomNavigation>
                </div>
              ) : activeStep === 1 ? (
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column",
                  }}
                >
                  {Array.from(
                    { length: newProgram.days.length },
                    (_, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <TextField
                          fullWidth
                          required={true}
                          /*                     error={!signupState.usernameAvailable}
                           */ label="Day Name"
                          type="text"
                          value={newProgram.days[index].dayName}
                          onChange={(e) => {
                            setNewProgram({
                              ...newProgram,
                              days: [
                                ...newProgram.days.slice(0, index), // Copy elements before the updated day
                                {
                                  dayName: e.target.value,
                                  dayNumber: index + 1,
                                  exercises: [
                                    {
                                      exercise: "",
                                      exerciseName: "",
                                      order: "",
                                      reps: [""],
                                    },
                                  ],
                                }, // Replace the day at the specified index
                                ...newProgram.days.slice(index + 1), // Copy elements after the updated day
                              ],
                            });
                          }}
                        />
                      </div>
                    )
                  )}
                  <Button
                    disabled={newProgram.days.length === 5 ? true : false}
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      setNewProgram({
                        ...newProgram,
                        days: [
                          ...newProgram.days,
                          { dayName: "", dayNumber: undefined, exercises: [] },
                        ],
                      });
                    }}
                  >
                    Add New Day
                  </Button>
                  <Button
                    disabled={newProgram.days.length === 1 ? true : false}
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      setNewProgram({
                        ...newProgram,
                        days: newProgram.days.slice(
                          0,
                          newProgram.days.length - 1
                        ),
                      });
                    }}
                  >
                    Remove Day
                  </Button>
                  <BottomNavigation
                    sx={{
                      zIndex: "1200",
                      position: "fixed",
                      bottom: 30,
                      left: "5%",
                      right: "5%",
                      borderRadius: "200px",
                      "@media (min-width: 1000px)": {
                        marginLeft: "30%",
                        marginRight: "30%",
                      },
                    }}
                  >
                    <BottomNavigationAction
                      onClick={() => {
                        setActiveStep(activeStep - 1);
                      }}
                      icon={<ArrowBackIos />}
                    />

                    {newProgram.days.every(
                      (item) => item.dayName.length != 0
                    ) && (
                      <BottomNavigationAction
                        onClick={() => {
                          setActiveStep(activeStep + 1);
                        }}
                        sx={{
                          color: "#d40000",
                        }}
                        icon={<ArrowForwardIos />}
                      />
                    )}
                  </BottomNavigation>
                </div>
              ) : activeStep === 2 ? (
                newProgram.days.map((day, index) => (
                  <div>
                    <Item
                      sx={{ flexDirection: "column", gap: "16px" }}
                      key={index}
                    >
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "300",
                          textAlign: "left",
                          fontFamily: "sans-serif",
                        }}
                      >
                        Day {day.dayNumber} - {day.dayName}
                      </div>
                      <Divider />
                      {day.exercises.map((_, indexExe) => (
                        <div
                          key={indexExe}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <Autocomplete
                            value={newProgram.days[index].exercises[indexExe]}
                            fullWidth
                            disablePortal
                            id="combo-box-demo"
                            options={programsState.exercises}
                            getOptionLabel={(option) => option.exerciseName}
                            isOptionEqualToValue={(option, value) =>
                              option.exerciseName === value.exerciseName ||
                              value.exerciseName === ""
                            }
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                required={true}
                                {...params}
                                /*                     error={!signupState.usernameAvailable}
                                 */ label="Exercise Name"
                                type="text"
                                /*  value={signupState.username} */
                              />
                            )}
                            onChange={(e, value) => {
                              setNewProgram({
                                ...newProgram,
                                days: [
                                  ...newProgram.days.slice(0, index), // Copy elements before the updated day
                                  {
                                    dayName: day.dayName,
                                    dayNumber: day.dayNumber,
                                    exercises: [
                                      ...day.exercises.slice(0, indexExe),
                                      {
                                        exercise: value?.ref ? value.ref : "",
                                        exerciseName: value?.exerciseName
                                          ? value.exerciseName
                                          : "",
                                        order: indexExe + 1,
                                        reps: day.exercises[indexExe].reps,
                                      },
                                      ...day.exercises.slice(indexExe + 1),
                                    ],
                                  }, // Replace the day at the specified index
                                  ...newProgram.days.slice(index + 1), // Copy elements after the updated day
                                ],
                              });
                            }}
                          />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {Array.from(
                              {
                                length: day.exercises[indexExe].reps.length,
                              },
                              (_, indexReps) => (
                                <div
                                  key={indexReps}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                  }}
                                >
                                  <TextField
                                    id="reps"
                                    type="number"
                                    value={
                                      newProgram.days[index].exercises[indexExe]
                                        .reps[indexReps]
                                    }
                                    onChange={(e) => {
                                      setNewProgram({
                                        ...newProgram,
                                        days: [
                                          ...newProgram.days.slice(0, index), // Copy elements before the updated day
                                          {
                                            dayName: day.dayName,
                                            dayNumber: day.dayNumber,
                                            exercises: [
                                              ...day.exercises.slice(
                                                0,
                                                indexExe
                                              ),
                                              {
                                                exercise:
                                                  day.exercises[indexExe]
                                                    .exercise,
                                                exerciseName:
                                                  day.exercises[indexExe]
                                                    .exerciseName,
                                                order: indexExe + 1,
                                                reps: [
                                                  ...day.exercises[
                                                    indexExe
                                                  ].reps.slice(0, indexReps),
                                                  e.target.value,

                                                  ...day.exercises[
                                                    indexExe
                                                  ].reps.slice(indexReps + 1),
                                                ],
                                              },
                                              ...day.exercises.slice(
                                                indexExe + 1
                                              ),
                                            ],
                                          }, // Replace the day at the specified index
                                          ...newProgram.days.slice(index + 1), // Copy elements after the updated day
                                        ],
                                      });
                                    }}
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          Reps
                                        </InputAdornment>
                                      ),
                                    }}
                                    inputProps={{
                                      inputMode: "numeric",
                                    }}
                                  />
                                  {indexReps + 1 ===
                                    day.exercises[indexExe].reps.length && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                                      <Button
                                        aria-label="add"
                                        variant="contained"
                                        shape="circle"
                                        fullWidth
                                        onClick={() => {
                                          setNewProgram({
                                            ...newProgram,
                                            days: [
                                              ...newProgram.days.slice(
                                                0,
                                                index
                                              ), // Copy elements before the updated day
                                              {
                                                dayName: day.dayName,
                                                dayNumber: day.dayNumber,
                                                exercises: [
                                                  ...day.exercises.slice(
                                                    0,
                                                    indexExe
                                                  ),
                                                  {
                                                    exercise:
                                                      day.exercises[indexExe]
                                                        .exercise,
                                                    exerciseName:
                                                      day.exercises[indexExe]
                                                        .exerciseName,
                                                    order:
                                                      day.exercises[indexExe]
                                                        .order,
                                                    reps: [
                                                      ...day.exercises[indexExe]
                                                        .reps,
                                                      "",
                                                    ],
                                                  },
                                                  ...day.exercises.slice(
                                                    indexExe + 1
                                                  ),
                                                ],
                                              }, // Replace the day at the specified index
                                              ...newProgram.days.slice(
                                                index + 1
                                              ), // Copy elements after the updated day
                                            ],
                                          });
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                      <Button
                                        disabled={
                                          day.exercises[indexExe].reps.length <=
                                          1
                                        }
                                        aria-label="add"
                                        variant="outlined"
                                        shape="circle"
                                        fullWidth
                                        onClick={() => {
                                          setNewProgram({
                                            ...newProgram,
                                            days: [
                                              ...newProgram.days.slice(
                                                0,
                                                index
                                              ), // Copy elements before the updated day
                                              {
                                                dayName: day.dayName,
                                                dayNumber: day.dayNumber,
                                                exercises: [
                                                  ...day.exercises.slice(
                                                    0,
                                                    indexExe
                                                  ),
                                                  {
                                                    exercise:
                                                      day.exercises[indexExe]
                                                        .exercise,
                                                    exerciseName:
                                                      day.exercises[indexExe]
                                                        .exerciseName,
                                                    order:
                                                      day.exercises[indexExe]
                                                        .order,

                                                    reps: day.exercises[
                                                      indexExe
                                                    ].reps.slice(
                                                      0,
                                                      day.exercises[indexExe]
                                                        .reps.length - 1
                                                    ),
                                                  },
                                                  ...day.exercises.slice(
                                                    indexExe + 1
                                                  ),
                                                ],
                                              }, // Replace the day at the specified index
                                              ...newProgram.days.slice(
                                                index + 1
                                              ), // Copy elements after the updated day
                                            ],
                                          });
                                        }}
                                      >
                                        <RemoveOutlined />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                      <Button
                        disabled={day.exercises.length === 20 ? true : false}
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setNewProgram({
                            ...newProgram,
                            days: [
                              ...newProgram.days.slice(0, index), // Copy elements before the updated day
                              {
                                dayName: day.dayName,
                                dayNumber: day.dayNumber,
                                exercises: [
                                  ...day.exercises,
                                  {
                                    exercise: "",
                                    exerciseName: "",
                                    order: "",
                                    reps: [""],
                                  },
                                ],
                              }, // Replace the day at the specified index
                              ...newProgram.days.slice(index + 1), // Copy elements after the updated day
                            ],
                          });
                        }}
                      >
                        Add New Exercise
                      </Button>
                      <Button
                        disabled={day.exercises.length === 1 ? true : false}
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setNewProgram({
                            ...newProgram,
                            days: [
                              ...newProgram.days.slice(0, index), // Copy elements before the updated day
                              {
                                dayName: day.dayName,
                                dayNumber: day.dayNumber,
                                exercises: day.exercises.slice(
                                  0,
                                  day.exercises.length - 1
                                ),
                              }, // Replace the day at the specified index
                              ...newProgram.days.slice(index + 1), // Copy elements after the updated day
                            ],
                          });
                        }}
                      >
                        Remove Exercise
                      </Button>
                    </Item>

                    <BottomNavigation
                      sx={{
                        zIndex: "1200",
                        position: "fixed",
                        bottom: 30,
                        left: "5%",
                        right: "5%",
                        borderRadius: "200px",
                        "@media (min-width: 1000px)": {
                          marginLeft: "30%",
                          marginRight: "30%",
                        },
                      }}
                    >
                      <BottomNavigationAction
                        onClick={() => {
                          setActiveStep(activeStep - 1);
                        }}
                        icon={<ArrowBackIos />}
                      />

                      {newProgram.days.every((day) => {
                        // Check if exercises array is not empty and all reps are not empty
                        return (
                          day.exercises.length !== 0 &&
                          day.exercises.every(
                            (exercise) =>
                              exercise.exercise.length !== 0 &&
                              exercise.reps.every((rep) => rep.length !== 0)
                          )
                        );
                      }) && (
                        <BottomNavigationAction
                          onClick={() => {
                            setActiveStep(activeStep + 1);
                          }}
                          sx={{
                            color: "#d40000",
                          }}
                          icon={<ArrowForwardIos />}
                        />
                      )}
                    </BottomNavigation>
                  </div>
                  ///////////////////////////////////////////////////////////// DO VALUE TO SHOW BETWEEN PAGES
                ))
              ) : activeStep === 3 ? (
                <div>
                  {newProgram.days.map((day, index) => (
                    <Item
                      sx={{
                        flexDirection: "column",
                        // Stack should provide some margin 12px coming from app.js need to look into it
                        marginTop: "12px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          padding: "1px 8px 8px",
                          fontSize: "18px",
                          fontWeight: "300",
                          textAlign: "left",
                          fontFamily: "sans-serif",
                        }}
                      >
                        Day {day.dayNumber} - {day.dayName}
                      </div>
                      <Divider />
                      <div style={{ marginTop: "16px" }}>
                        {day["exercises"].map((exe, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={index}
                            >
                              <div style={{ textAlign: "left" }}>
                                {exe["exerciseName"]}
                              </div>
                              <div style={{ textAlign: "left" }}>
                                {exe["reps"].map((rep, index) => {
                                  if (index === exe["reps"].length - 1) {
                                    return rep;
                                  } else {
                                    return rep + " / ";
                                  }
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Item>
                  ))}
                  <BottomNavigation
                    sx={{
                      zIndex: "1200",
                      position: "fixed",
                      bottom: 30,
                      left: "5%",
                      right: "5%",
                      borderRadius: "200px",
                      "@media (min-width: 1000px)": {
                        marginLeft: "30%",
                        marginRight: "30%",
                      },
                    }}
                  >
                    <BottomNavigationAction
                      onClick={() => {
                        setActiveStep(activeStep - 1);
                      }}
                      icon={<ArrowBackIos />}
                    />

                    <BottomNavigationAction
                      onClick={() => {
                        setActiveStep(activeStep + 1);
                      }}
                      sx={{
                        color: "#d40000",
                      }}
                      icon={<ArrowForwardIos />}
                    />
                  </BottomNavigation>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "200px",
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: "#d40000",
                      height: "100px !important",
                      width: "100px !important",
                    }}
                  />
                  <CheckCircleOutlineOutlinedIcon
                    sx={{ color: "#d40000", height: "120px", width: "120px" }}
                  />
                </div>
              )}
            </Stack>
          </DialogSlide>
        </Stack>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default Programs;
