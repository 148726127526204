import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { setNewValue } from "./navbarSlice";

import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import StackedLineChartRoundedIcon from "@mui/icons-material/StackedLineChartRounded";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import AlarmOnRoundedIcon from "@mui/icons-material/AlarmOnRounded";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { useNavigate } from "react-router-dom";
import StackedLineChartOutlinedIcon from "@mui/icons-material/StackedLineChartOutlined";
/* import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
 */
/* const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -2,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
 */
function Navbar(props) {
  //const [value, setValue] = React.useState(0);
  //const value = useSelector((state) => state.navbar.value);
  const value = useSelector((state) => state.navbar.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    dispatch(setNewValue(newValue));
  };
  return (
    <div>
      <BottomNavigation
        sx={{
          zIndex: "1200",
          position: "fixed",
          bottom: 30,
          left: "5%",
          right: "5%",
          borderRadius: "200px",
          "@media (min-width: 1000px)": {
            marginLeft: "30%",
            marginRight: "30%",
          },
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
      >
        {/*     <BottomItem
            onClick={() => {
              navigate("/");
            }}
            icon={<ClearAllRoundedIcon />}
          /> */}
        <BottomNavigationAction
          onClick={() => {
            navigate("/programs");
          }}
          icon={<DashboardOutlinedIcon />}
        />
        <BottomNavigationAction
          onClick={() => {
            navigate("/workout");
          }}
          icon={<AlarmOnRoundedIcon />}
        />

        <BottomNavigationAction
          onClick={() => {
            navigate("/analytics");
          }}
          icon={
            /* <StyledBadge
                color="primary"
                  overlap="circular"
                badgeContent={value} 
              >*/
            <StackedLineChartOutlinedIcon />
            /*  </StyledBadge> */
          }
        />

        <BottomNavigationAction
          onClick={() => {
            navigate("/profile");
          }}
          icon={<PersonOutlineRoundedIcon />}
        />
      </BottomNavigation>
    </div>
  );
}

export default Navbar;
