import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../navbar/navbar";
import Item from "../../components/itemPaper/Item";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import { CChart } from "@coreui/react-chartjs";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { InfoOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Divider } from "@mui/material";
import AppBar from "../../components/appBar/appBar";
import { FitnessCenterOutlined } from "@mui/icons-material";
import ButtonGroup from "@mui/material/ButtonGroup";

function Notifications(props) {
  const notificationsState = useSelector((state) => state.notifications);
  const [selectedDataset, setSelectedDataset] = useState({
    maxKgSession: false,
    totalKg: false,
    sumReps: false,
    avgKg: false,
  });

  return (
    <div>
      <Navbar />
      <Helmet>
        <title>Analytics</title>
      </Helmet>
      {notificationsState.status === "succeeded" ? (
        <Stack>
          <AppBar title={"Analytics"} />
          {/*   {notificationsState.notifications.length !== 0 ? (
            notificationsState.notifications.map((item, index) => {
              return (
                <Item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#d40000",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                      color: "#fff",
                    }}
                  >
                    {item.type === "msg" ? (
                      <NotificationsNoneIcon />
                    ) : item.type === "info" ? (
                      <InfoOutlined />
                    ) : null}
                  </div>
                  {item.msg}
                </Item>
              );
            })
          ) : (
            <Item
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "16px",
              }}
            >
              <Item
                sx={{
                  margin: "-16px",
                  background: "#d40000",
                  marginRight: "16px",
                }}
              />
              No Notifications
            </Item>
          )} */}
          {notificationsState.example.length !== 0 ? (
            Object.keys(notificationsState.example).map((exe, index) => {
              console.log(notificationsState.example[exe]);
              return (
                <Item
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "45px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      textAlign: "left",
                      marginBottom: "16px",
                    }}
                  >
                    {notificationsState.example[exe]["name"]}
                  </div>

                  <Divider sx={{ marginBottom: "16px" }} />
                  <CChart
                    type="line"
                    height={250}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      tooltip: {
                        enabled: false, // <-- this option disables tooltips
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false, // Hide x-axis grid lines
                          },
                          title: {
                            display: true,
                            text: "Date / KG",
                          },
                        },
                        y: {
                          grid: {
                            display: false, // Hide x-axis grid lines
                          },
                          title: {
                            display: false,
                            text: "KG",
                          },
                          suggestedMin:
                            Math.min(
                              ...notificationsState.example[exe]["totalKg"]
                            ) - 0.8, // Minimum value with buffer
                          suggestedMax:
                            Math.max(
                              ...notificationsState.example[exe]["totalKg"]
                            ) + 0.8, // Maximum value with buffer
                        },
                      },
                      plugins: {
                        legend: {
                          display: true,
                          position: "bottom", // Position the legend at the bottom
                          labels: {
                            font: {
                              size: 10, // Adjust the font size as needed
                            },
                            color: "gray", // Adjust the font color as needed
                            padding: 12, // Add some padding between legend items
                            usePointStyle: true, // Use point style for legend items
                            boxWidth: 10, // Set the size of the point style (circle)
                            itemMargin: 10,
                          },
                        },
                      },
                    }}
                    data={{
                      labels: notificationsState.example[exe]["dates"],
                      datasets: [
                        {
                          label: "Max Weight Lifted",
                          data: notificationsState.example[exe]["maxKgSession"],
                          pointRadius: 0, // Hide dots
                          tension: 0.4, // Adjust the curve tension (0.4 for a moderate curve)
                          borderColor: "#d40000", // Set the line color to #d40000
                          backgroundColor: "#d40000", // Fill color
                          hidden: selectedDataset.maxKgSession,
                        },
                        {
                          label: "Total Weight Lifted",
                          data: notificationsState.example[exe]["totalKg"],
                          pointRadius: 0, // Hide dots
                          tension: 0.4, // Adjust the curve tension (0.4 for a moderate curve)
                          borderColor: "#FF6600", // Set the line color to #d40000
                          backgroundColor: "#FF6600", // Fill color
                          hidden: selectedDataset.totalKg,
                        },
                        {
                          label: "Total Reps Done",
                          data: notificationsState.example[exe]["sumReps"],
                          pointRadius: 0, // Hide dots
                          tension: 0.4, // Adjust the curve tension (0.4 for a moderate curve)
                          borderColor: "#FFD700", // Set the line color to #d40000
                          backgroundColor: "#FFD700", // Fill color
                          hidden: selectedDataset.sumReps,
                        },
                        {
                          label: "Average Weight Lifted",
                          data: notificationsState.example[exe]["avgKg"],
                          pointRadius: 0, // Hide dots
                          tension: 0.4, // Adjust the curve tension (0.4 for a moderate curve)
                          borderColor: "#6600CC", // Set the line color to #d40000
                          backgroundColor: "#6600CC", // Fill color
                          hidden: selectedDataset.avgKg,
                        },

                        /* 
                        ******** SHOULD BE LAST notificationsState.example[exe] COMPARE ********
                        { 
                          label: "My Second dataset",
                          backgroundColor: "rgba(151, 187, 205, 0.2)",
                          borderColor: "rgba(151, 187, 205, 1)",
                          pointBackgroundColor:
                            "rgba(151, 187, 205, 1)",
                          pointBorderColor: "#fff",
                          data: [
                            50, 12, 28, 29, 7, 25, 12, 70, 60, 29, 7,
                            25, 12, 70, 60, 29, 7, 25, 12, 70, 60, 29,
                            7, 25, 12, 70, 60, 29, 7, 25, 12, 70, 60,
                          ],
                        }, */
                      ],
                    }}
                  />
                  {/*    <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "16px",
                        textAlign: "left",
                        minWidth: "100px",
                      }}
                    >
                      <ButtonGroup>
                        <Button
                          onClick={() =>
                            setSelectedDataset({
                              maxKgSession: selectedDataset.maxKgSession,
                              totalKg: !selectedDataset.totalKg,
                              sumReps: selectedDataset.sumReps,
                              avgKg: selectedDataset.avgKg,
                            })
                          }
                        >
                          Max Weight
                        </Button>
                        <Button
                          onClick={() =>
                            setSelectedDataset({
                              maxKgSession: !selectedDataset.maxKgSession,
                              totalKg: selectedDataset.totalKg,
                              sumReps: selectedDataset.sumReps,
                              avgKg: selectedDataset.avgKg,
                            })
                          }
                        >
                          Total Weight
                        </Button>
                      </ButtonGroup>
                    </div>

                    <div
                      style={{
                        marginTop: "16px",
                        textAlign: "left",
                        minWidth: "100px",
                      }}
                    >
                      <ButtonGroup>
                        <Button
                          onClick={() =>
                            setSelectedDataset({
                              maxKgSession: selectedDataset.maxKgSession,
                              totalKg: selectedDataset.totalKg,
                              sumReps: selectedDataset.sumReps,
                              avgKg: !selectedDataset.avgKg,
                            })
                          }
                        >
                          Average Weight
                        </Button>
                        <Button
                          onClick={() =>
                            setSelectedDataset({
                              maxKgSession: selectedDataset.maxKgSession,
                              totalKg: selectedDataset.totalKg,
                              sumReps: !selectedDataset.sumReps,
                              avgKg: selectedDataset.avgKg,
                            })
                          }
                        >
                          Total Reps
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div> */}

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "16px",
                        textAlign: "left",
                        minWidth: "100px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "100",
                          flex: 1,
                        }}
                      >
                        Last Weight Lifted
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "300",
                          flex: 1,
                        }}
                      >
                        {notificationsState.example[exe]["lastKg"] + " KG"}
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "16px",
                        textAlign: "left",
                        minWidth: "100px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "100",
                        }}
                      >
                        Max Last Weight Lifted
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "300",
                        }}
                      >
                        {notificationsState.example[exe]["maxKg"] + " KG"}
                      </div>
                    </div>
                  </div>
                </Item>
              );
            })
          ) : (
            <Item
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "16px",
              }}
            >
              <Item
                sx={{
                  margin: "-16px",
                  background: "#d40000",
                  marginRight: "16px",
                }}
              />
              No Data
            </Item>
          )}
        </Stack>
      ) : notificationsState.status === "loading" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Item
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "16px",
          }}
        >
          <Item
            sx={{
              margin: "-16px",
              background: "#d40000",
              marginRight: "16px",
            }}
          />
          No Data
        </Item>
      )}
    </div>
  );
}

export default Notifications;
