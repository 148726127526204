import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";

export const firebase_Signin = createAsyncThunk(
  "signinSlice/firebase_Signin",
  async (_, ThunkAPI) => {
    const state = ThunkAPI.getState().signin;
    try {
      await signInWithEmailAndPassword(auth, state.email, state.password);
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);
export const signinSlice = createSlice({
  name: "signin",
  initialState: {
    status: "idle",
    email: "",
    password: "",
    msg: "",
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.email = "";
      state.password = "";
      state.msg = "";
    },
    setEmail: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.status = "idle";
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.status = "idle";
      state.password = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_Signin.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_Signin.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(firebase_Signin.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
        state.email = "";
        state.password = "";
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset, setEmail, setPassword } = signinSlice.actions;

export default signinSlice.reducer;
