import React from "react";

export default function AppBar({ title, subtitle, actionButton }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "24px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <div
            style={{
              fontSize: "25px",
              fontWeight: "600",
              color: "#d40000",
            }}
          >
            {title}
          </div>
          <div
            style={{
              fontSize: "18px",
              fontWeight: "300",
            }}
          >
            {subtitle}
          </div>
        </div>
      </div>
      <div>{actionButton}</div>
    </div>
  );
}
