import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { db } from "../../config/firebase";
import {
  collection,
  doc,
  query,
  getDocs,
  where,
  orderBy,
  updateDoc,
} from "firebase/firestore";

export const firebase_GetProgramDetails = createAsyncThunk(
  "programDetailsSlice/firebase_GetProgramDetails",
  async (programID, ThunkAPI) => {
    try {
      const programDetails = [];
      const [querySnapshotDays, querySnapshotExercisesNames] =
        await Promise.all([
          getDocs(
            query(
              collection(db, "programs", programID, "Days"),
              orderBy("dayNumber", "asc")
            )
          ),
          getDocs(query(collection(db, "exercises"))),
        ]);

      for await (const doc of querySnapshotDays.docs) {
        let exercises = [];
        const querySnapshotExercises = await getDocs(
          query(
            collection(db, "programs", programID, "Days", doc.id, "exercises"),
            orderBy("order", "asc")
          )
        );
        exercises = [];
        for await (const exe of querySnapshotExercises.docs) {
          const exeName =
            querySnapshotExercisesNames.docs[
              querySnapshotExercisesNames.docs.findIndex(
                (exeName) => exeName.id === exe.data()["exercise"].id
              )
            ];

          exercises.push({
            ...exe.data(),
            exerciseName: exeName.data()["exerciseName"],
          });
        }
        programDetails.push({
          ...doc.data(),
          exercises: exercises.sort(function (a, b) {
            return a.order - b.order;
          }),
        });
      }

      return programDetails;
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);
export const firebase_SetProgramDetails = createAsyncThunk(
  "programDetailsSlice/firebase_SetProgramDetails",
  async (programID, ThunkAPI) => {
    try {
      const state = ThunkAPI.getState().firebaseAuth;
      await updateDoc(doc(db, "users", state.user.uid), {
        currentProgram: doc(db, "programs", programID),
        currentDay: (
          await getDocs(
            query(
              collection(db, "programs", programID, "Days"),
              where("dayNumber", "==", 1)
            )
          )
        ).docs[0].ref,
      });

      return true;
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);
export const programDetailsSlice = createSlice({
  name: "programDetails",
  initialState: {
    status: "idle",
    setStatus: "idle",
    programDetails: [],
    msg: "",
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.setStatus = "idle";
      state.programDetails = [];
      state.msg = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_GetProgramDetails.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_GetProgramDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.programDetails = action.payload;
      })
      .addCase(firebase_GetProgramDetails.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
      })
      .addCase(firebase_SetProgramDetails.pending, (state, action) => {
        state.setStatus = "loading";
        state.msg = "";
      })
      .addCase(firebase_SetProgramDetails.fulfilled, (state, action) => {
        state.setStatus = "succeeded";
      })
      .addCase(firebase_SetProgramDetails.rejected, (state, action) => {
        state.setStatus = "failed";
        state.msg = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = programDetailsSlice.actions;

export default programDetailsSlice.reducer;
