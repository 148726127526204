import React from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashbaord from "./features/dashboard/dashbaord";
import Programs from "./features/programs/programs";
import Workout from "./features/workout/workout";
import Notifications from "./features/notifications/notifications";
import Profile from "./features/profile/profile";
import ProgramDetails from "./features/programs/programDetails";
import WorkoutProgress from "./features/workout/workoutProgress";
import Signin from "./features/auth/signin";
import {
  auth,
  db,
  onMessageListener,
  requestForToken,
} from "./config/firebase";
import Signup from "./features/auth/signup";
import PrivateRoutes from "./utils/PrivateRoutes";
import PublicRoutes from "./utils/PublicRoutes";
import { onAuthStateChanged } from "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import {
  firebase_GetUserProfile,
  reset,
  setCurrentUser,
} from "./config/firebaseAuthSlice";
import { AnimatePresence } from "framer-motion";
import ResetPassword from "./features/auth/resetPassword";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import WorkoutHistory from "./features/workout/workoutHistory";
import { firebase_GetWorkoutHistory } from "./features/workout/workoutSlice";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firebase_GetPrograms } from "./features/programs/programsSlice";
import { firebase_GetNotifications } from "./features/notifications/notificationsSlice";

// Themeing MUI
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { setNewValue } from "./features/navbar/navbarSlice";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.firebaseAuth);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: prefersDarkMode ? "#000" : "#fff",
          },
        },
      },
      MuiTextField: {
        defaultProps: { size: "small" },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            marginTop: "10px",
            padding: "2px 10px",
            borderRadius: "200px",
            background: "rgba(30,30,30,0.8)",
            backdropFilter: "blur(3px)",
            color: "#fff",
          },
        },
      },
      MuiDialogTitle: { styleOverrides: { root: { padding: "0px" } } },
      MuiDialogContent: {
        styleOverrides: { root: { padding: "0px" } },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            gap: "15px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "16px",
          },
        },
      },
      MuiStack: {
        defaultProps: {
          spacing: 1.5,
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            background: prefersDarkMode
              ? "rgba(30,30,30,0.8)"
              : "rgba(255,255,255,0.8)",
            backdropFilter: "blur(3px)",
            boxShadow: prefersDarkMode
              ? "rgb(0 0 0 / 70%) 0px 0px 15px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"
              : "rgba(145, 158, 171, 0.3) 0px 0px 5px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: { borderRadius: "200px" },
        },
        defaultProps: {
          size: "small",
          disableRipple: true,
          disableElevation: true,
        },
      },
      MuiBottomNavigationAction: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },

    palette: {
      mode: prefersDarkMode ? "dark" : "light",
      primary: {
        main: "#d40000",
      },
    },
    shape: {
      borderRadius: 10,
    },
  });

  const background_color = prefersDarkMode ? "#000" : "#fff";
  const theme_color = prefersDarkMode ? "#000" : "#fff";

  // Update the manifest dynamically
  useEffect(() => {
    const manifest = {
      background_color,
      theme_color,
    };

    // Set the manifest data
    const manifestBlob = new Blob([JSON.stringify(manifest, null, 2)], {
      type: "application/json",
    });

    const manifestUrl = URL.createObjectURL(manifestBlob);
    const link = document.querySelector("link[rel='manifest']");

    // Update the manifest file in the DOM
    if (link) {
      link.href = manifestUrl;
    }
  }, [background_color, theme_color]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(setCurrentUser(user));
      } else {
        dispatch(reset());
        dispatch(setNewValue(0));
        // User is signed out
        // ...
      }
    });

    return () => unsubscribe();
    //eslint-disable-next-line
  }, [appState.user]);
  useEffect(() => {
    if (appState.user !== null && appState.userProfile !== null) {
      try {
        requestForToken();
      } catch {
        console.log("Notifications Won't Work");
      }
      onMessageListener()
        .then((payload) => {
          console.log("New Notification");
        })
        .catch((err) => console.log("failed: ", err));

      const unsubscribeWorkoutHistory = onSnapshot(
        query(
          collection(db, "workouts"),
          where("uid", "==", appState.user.uid),
          orderBy("date", "desc")
        ),
        () => {
          dispatch(firebase_GetWorkoutHistory());
        }
      );

      const unsubscribePrograms = onSnapshot(
        query(collection(db, "programs")),
        () => {
          dispatch(firebase_GetPrograms());
        }
      );
      const unsubscribeNotifications = onSnapshot(
        query(
          collection(db, "notifications"),
          orderBy("created_at", "desc"),
          where("uid", "==", appState.user.uid)
        ),
        () => {
          dispatch(firebase_GetNotifications());
        }
      );

      return () => {
        unsubscribeNotifications();
        unsubscribePrograms();
        unsubscribeWorkoutHistory();
      };
    }
    // eslint-disable-next-line
  }, [appState.user, appState.userProfile]);
  useEffect(() => {
    if (appState.user !== null) {
      const unsubscribeUserProfile = onSnapshot(
        doc(db, "users", appState.user.uid),
        () => {
          dispatch(firebase_GetUserProfile());
        }
      );
      return () => {
        unsubscribeUserProfile();
      };
    }
    // eslint-disable-next-line
  }, [appState.user]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {appState.status === "succeeded" || appState.status === "idle" ? (
          <AnimatePresence>
            <Routes location={location}>
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={<Dashbaord />} exact />
                <Route path="/programs" element={<Programs />} />
                <Route
                  path="/programs/:programID/:programName"
                  element={<ProgramDetails />}
                />
                <Route path="/workout" element={<Workout />} />
                <Route
                  path="/workout/start/programs/:programID/Days/:dayID"
                  element={<WorkoutProgress />}
                />
                <Route
                  path="/workout/:workoutHistoryID"
                  element={<WorkoutHistory />}
                />

                <Route path="/analytics" element={<Notifications />} />
                <Route path="/profile" element={<Profile />} />
              </Route>
              <Route element={<PublicRoutes />}>
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
              </Route>
            </Routes>
          </AnimatePresence>
        ) : (
          <CircularProgress />
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
