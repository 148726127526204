import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { db } from "../../config/firebase";
import { collection, query, getDocs } from "firebase/firestore";

export const firebase_GetPrograms = createAsyncThunk(
  "programsSlice/firebase_GetPrograms",
  async (_, ThunkAPI) => {
    try {
      const programs = [];
      const exercises = [];

      const [querySnapshotPrograms, querySnapshotExercisesNames] =
        await Promise.all([
          getDocs(query(collection(db, "programs"))),
          getDocs(query(collection(db, "exercises"))),
        ]);

      for (const program of querySnapshotPrograms.docs) {
        programs.push({ ...program.data(), id: program.id, ref: program.ref });
      }
      for (const exerciese of querySnapshotExercisesNames.docs) {
        exercises.push({
          ...exerciese.data(),
          id: exerciese.id,
          ref: exerciese.ref,
        });
      }
      return {
        programs: programs,
        exercises: exercises,
      };
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);

export const programsSlice = createSlice({
  name: "programs",
  initialState: {
    status: "idle",
    programs: [],
    exercises: [],
    msg: "",
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.programs = [];
      state.exercises = [];
      state.msg = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_GetPrograms.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_GetPrograms.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.programs = action.payload.programs;
        state.exercises = action.payload.exercises;
      })
      .addCase(firebase_GetPrograms.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
        state.programs = [];
        state.exercises = [];
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = programsSlice.actions;

export default programsSlice.reducer;
