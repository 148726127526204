import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import { db } from "../config/firebase";

import { getDoc, doc } from "firebase/firestore";

export const firebase_GetUserProfile = createAsyncThunk(
  "firebaseAuthSlice/firebase_GetUserProfile",
  async (_, ThunkAPI) => {
    const state = ThunkAPI.getState().firebaseAuth;
    try {
      const querySnapshotUserProfile = await getDoc(
        doc(db, "users", state.user.uid)
      );
      return querySnapshotUserProfile.data();
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);
export const setCurrentUser = createAsyncThunk(
  "firebaseAuthSlice/setCurrentUser",
  async (user, ThunkAPI) => {
    try {
      return user;
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);

export const firebaseAuthSlice = createSlice({
  name: "firebaseAuth",
  initialState: {
    status: "idle",
    updateStatus: "idle",
    user: null,
    userProfile: null,
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.user = null;
      state.userProfile = null;
    },
    logout: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.status = "idle";
      signOut(auth);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_GetUserProfile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(firebase_GetUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.userProfile = action.payload;
      })
      .addCase(firebase_GetUserProfile.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(setCurrentUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(setCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "succeeded";
      })
      .addCase(setCurrentUser.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset, logout } = firebaseAuthSlice.actions;

export default firebaseAuthSlice.reducer;
