import { React, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import PauseIcon from "@mui/icons-material/Pause";
import PlayIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import Item from "../../components/itemPaper/Item";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import List from "@mui/material/List";
import { useDispatch, useSelector } from "react-redux";
import {
  firebase_GetWorkoutExercises,
  pushExerciseLogs,
  firebase_SetExercisesLogs,
  reset,
} from "./workoutProgressSlice";
import { ArrowForwardIos, EditNote } from "@mui/icons-material";
import BreakPill from "../../components/breakPill/breakPill";
import TimelapseOutlinedIcon from "@mui/icons-material/TimelapseOutlined";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

function WorkoutProgress(props) {
  const [exerciseLogs, setExerciseLogs] = useState(null);
  const [reps, setReps] = useState("");
  const [kg, setKg] = useState("");
  const [startBreak, setStartBreak] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  // eslint-disable-next-line
  let breaktime = useMemo(() => new Date().getTime() + 45000, [startBreak]);
  // state to store time
  const [time, setTime] = useState(0);
  // state to check stopwatch running or not
  const [isRunning, setIsRunning] = useState(true);
  const params = useParams();
  const dayID = params.dayID;
  const programID = params.programID;
  const [warning, setWarning] = useState(false);
  const [next, setNext] = useState(false);
  const [update, setUpdate] = useState({
    state: false,
    index: null,
    kg: "",
    reps: "",
  });
  const workoutProgressState = useSelector((state) => state.workoutProgress);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Hours calculation
  const hours = Math.floor((time / 3600000) % 24);
  // Minutes calculation
  const minutes = Math.floor(time / 60000) % 60;
  // Seconds calculation
  const seconds = Math.floor(time / 1000) % 60;
  // Milliseconds calculation
  const milliseconds = time % 100;
  // eslint-disable-next-line
  let start = useMemo(() => new Date().getTime() - time, [isRunning]);
  useEffect(() => {
    let intervalId = null;
    if (isRunning) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => {
        let current = new Date();
        // Advance Normal Time
        setTime(() => +current - +start);
        // If break is finished send notification
        if (timeLeft < 0 || timeLeft === 0) {
          setStartBreak(false);
          setTimeLeft(null);
          // eslint-disable-next-line
          const notification = new Notification("ADRELIEN FIT", {
            body: "Your Break is Up",
          });
        }
        // Advance Break Time
        if (startBreak && !(timeLeft < 0 || timeLeft === 0)) {
          setTimeLeft(() => +breaktime - +current);
        }
      }, 10);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [isRunning, startBreak, timeLeft]);

  useEffect(() => {
    setIsRunning(!warning);
  }, [warning]);
  useEffect(() => {
    if (workoutProgressState.setStatus === "succeeded") {
      navigate("/workout");
    }
  }, [workoutProgressState.setStatus, navigate]);

  useEffect(() => {
    dispatch(firebase_GetWorkoutExercises([programID, dayID]));

    return () => {
      dispatch(reset());
      // all the clean-ups related to this component
      // I'll run when the component is unmounted
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      {workoutProgressState.status === "succeeded" &&
      workoutProgressState.setStatus === "idle" ? (
        <div>
          <Stack>
            <Item sx={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#d40000",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                      color: "#fff",
                    }}
                  >
                    {exerciseLogs
                      ? exerciseLogs.length +
                        "/" +
                        workoutProgressState.currentExercise["reps"].length
                      : 0 +
                        "/" +
                        workoutProgressState.currentExercise["reps"].length}
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: "12px", fontWeight: "100" }}>
                      {hours.toString().padStart(2, "0")}:
                      {minutes.toString().padStart(2, "0")}:
                      {seconds.toString().padStart(2, "0")}:
                      {milliseconds.toString().padStart(2, "0")}
                    </div>
                    <div style={{ fontSize: "18px", fontWeight: "300" }}>
                      {workoutProgressState.currentExercise["exerciseName"]}
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: "100" }}>
                      {workoutProgressState.currentExercise
                        ? workoutProgressState.currentExercise["reps"].map(
                            (item, index) => {
                              if (
                                index ===
                                workoutProgressState.currentExercise["reps"]
                                  .length -
                                  1
                              ) {
                                return item;
                              } else {
                                return item + " / ";
                              }
                            }
                          )
                        : null}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "16px",
                }}
              >
                <img
                  alt=""
                  src={workoutProgressState.currentExercise["img"]}
                  style={{ height: "50%", width: "50%" }}
                />
              </div>
            </Item>

            <Item sx={{ marginTop: "16px", display: "block" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  id="kg"
                  type="number"
                  value={kg}
                  onChange={(event) => setKg(event.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">KG</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    inputMode: "decimal",
                  }}
                />
                <TextField
                  id="reps"
                  type="number"
                  value={reps}
                  onChange={(event) => setReps(event.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Reps</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    inputMode: "numeric",
                  }}
                />
                <Button
                  aria-label="add"
                  variant="contained"
                  shape="circle"
                  sx={{
                    borderRadius: "50%",
                    minWidth: "40px",
                    minHeight: "40px",
                    padding: "5px",
                  }}
                  onClick={() => {
                    if (kg !== "" && reps !== "") {
                      if (exerciseLogs == null) {
                        setExerciseLogs([[kg, reps]]);
                        setKg("");
                        setReps("");
                        setStartBreak(true);
                      } else {
                        setExerciseLogs([[kg, reps], ...exerciseLogs]);
                        setKg("");
                        setReps("");
                        setStartBreak(true);
                      }
                    }
                  }}
                >
                  <AddIcon />
                </Button>
              </div>
              {exerciseLogs ? (
                <div
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <List
                    sx={{
                      maxHeight: "15%",
                      overflow: "auto",
                      width: "100%",
                      "&::-webkit-scrollbar": { display: "none" },
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                    }}
                  >
                    {exerciseLogs
                      ? exerciseLogs.map((item, index) => {
                          return (
                            <ListItem disablePadding key={index}>
                              <ListItemButton
                                onClick={() => {
                                  setKg(item[0]);
                                  setReps(item[1]);
                                }}
                              >
                                <ListItemText
                                  key={index + "KG"}
                                  primary={item[0] + " Kg"}
                                />
                                <ListItemText
                                  key={index + "REPS"}
                                  primary={item[1] + " Reps"}
                                />
                              </ListItemButton>
                              <Button
                                onClick={() => {
                                  setUpdate({
                                    state: true,
                                    index: index,
                                    kg: item[0],
                                    reps: item[1],
                                  });
                                }}
                              >
                                <EditNote />
                              </Button>
                            </ListItem>
                          );
                        })
                      : null}
                  </List>
                </div>
              ) : null}
            </Item>
          </Stack>
          <BreakPill
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setStartBreak(false);
                  setTimeLeft(null);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
            open={startBreak}
            icon={<TimelapseOutlinedIcon />}
            title={"Break"}
            body={
              Math.floor(timeLeft / 3600000)
                .toString()
                .padStart(2, "0") +
              ":" +
              Math.floor((timeLeft % 3600000) / 60000)
                .toString()
                .padStart(2, "0") +
              ":" +
              Math.floor((timeLeft % 60000) / 1000)
                .toString()
                .padStart(2, "0") +
              ":" +
              Math.floor(timeLeft % 100)
                .toString()
                .padStart(2, "0")
            }
          />
          <BottomNavigation
            sx={{
              zIndex: "1200",
              position: "fixed",
              bottom: 30,
              left: "5%",
              right: "5%",
              borderRadius: "200px",
              "@media (min-width: 1000px)": {
                marginLeft: "30%",
                marginRight: "30%",
              },
            }}
          >
            <BottomNavigationAction
              onClick={() => setWarning(true)}
              icon={<StopIcon />}
            />
            <BottomNavigationAction
              sx={{ color: "#d40000" }}
              onClick={() => {
                setNext(true);
              }}
              icon={<ArrowForwardIos />}
            />
            <BottomNavigationAction
              onClick={() => setIsRunning(!isRunning)}
              icon={isRunning ? <PauseIcon /> : <PlayIcon />}
            />
          </BottomNavigation>

          <Dialog
            open={warning}
            onClose={() => setWarning(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"End Workout"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to end your workout?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Stack direction="row">
                <Button onClick={() => setWarning(false)} variant="outlined">
                  Cancel
                </Button>

                <Button
                  onClick={() => {
                    if (exerciseLogs !== null) {
                      dispatch(
                        pushExerciseLogs({
                          ...workoutProgressState.currentExercise,
                          logs: exerciseLogs,
                        })
                      );
                    }
                    dispatch(
                      firebase_SetExercisesLogs([
                        programID,
                        dayID,
                        hours.toString().padStart(2, "0"),
                        minutes.toString().padStart(2, "0"),
                        seconds.toString().padStart(2, "0"),
                      ])
                    );
                    setWarning(false);
                  }}
                  variant="contained"
                >
                  END
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
          <Dialog
            open={next}
            onClose={() => setNext(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Next Exercise"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ready For Next Exercise?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Stack direction="row">
                <Button onClick={() => setNext(false)} variant="outlined">
                  Cancel
                </Button>

                <Button
                  onClick={() => {
                    if (workoutProgressState.workoutExercises.length === 0) {
                      setWarning(true);
                    } else {
                      dispatch(
                        pushExerciseLogs({
                          ...workoutProgressState.currentExercise,
                          logs: exerciseLogs,
                        })
                      );
                      setExerciseLogs(null);
                      setKg("");
                      setReps("");
                    }
                    setNext(false);
                  }}
                  variant="contained"
                >
                  NEXT
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
          <Dialog
            open={update.state}
            onClose={() =>
              setUpdate({
                state: false,
                index: null,
                kg: "",
                reps: "",
              })
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Edit Exercise"}</DialogTitle>
            <DialogContent>
              <Stack direction="row">
                <TextField
                  id="kg"
                  type="number"
                  value={update.kg}
                  onChange={(event) =>
                    setUpdate({ ...update, kg: event.target.value })
                  }
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">KG</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    inputMode: "decimal",
                  }}
                />
                <TextField
                  id="reps"
                  type="number"
                  value={update.reps}
                  onChange={(event) =>
                    setUpdate({ ...update, reps: event.target.value })
                  }
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Reps</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    inputMode: "numeric",
                  }}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Stack direction="row">
                <Button
                  onClick={() => {
                    if (
                      exerciseLogs.filter((_, index) => index !== update.index)
                        .length !== 0
                    ) {
                      setExerciseLogs(
                        exerciseLogs.filter(
                          (_, index) => index !== update.index
                        )
                      );
                    } else {
                      setExerciseLogs(null);
                    }

                    setUpdate({
                      state: false,
                      index: null,
                      kg: "",
                      reps: "",
                    });
                  }}
                  variant="outlined"
                >
                  Delete
                </Button>

                <Button
                  onClick={() => {
                    if (update.kg.length !== 0 && update.reps.length !== 0) {
                      exerciseLogs[update.index][0] = update.kg;
                      exerciseLogs[update.index][1] = update.reps;
                    }

                    setUpdate({
                      state: false,
                      index: null,
                      kg: "",
                      reps: "",
                    });
                  }}
                  variant="contained"
                >
                  Update
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default WorkoutProgress;
