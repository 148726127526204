import React from "react";
import Grid from "@mui/material/Grid";
import { CChart } from "@coreui/react-chartjs";
import { Divider } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Navbar from "../navbar/navbar";
import Item from "../../components/itemPaper/Item";
import { motion } from "framer-motion";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
function Dashbaord(props) {
  return (
    <div>
      <Navbar />
      <Item>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Item
              sx={{
                background: "#d40000",
                marginRight: "16px",
                padding: "10px",
                height: "20px",
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
              }}
            >
              <HandymanOutlinedIcon />
            </Item>
            <div style={{ textAlign: "left" }}>
              <div style={{ fontSize: "12px", fontWeight: "100" }}>
                Adrelien FIT
              </div>
              <div style={{ fontSize: "18px", fontWeight: "300" }}>
                This Page Is Under Development. Feel Free Navigating To Other
                Pages
              </div>
              <div style={{ fontSize: "12px", fontWeight: "100" }}>v0.1</div>
            </div>
          </div>
        </div>
      </Item>
    </div>
    /*  <div>
      <Navbar />

      <Helmet>
        <title>Dashbaord</title>
      </Helmet>
      {/*       <Header title="Dashboard" />
       
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 4, sm: 4, md: 12 }}
      >
        <Grid item xs={2} sm={2} md={3} key={0}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            <Item sx={{ background: "rgb(208, 242, 255)" }}>
              <div>
                <div
                  style={{
                    paddingTop: "40px",
                    paddingBottom: "40px",
                    display: "block",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "700",
                      fontSize: "24px",
                      margin: "0px",
                    }}
                  >
                    4.7 L
                  </h3>
                  <h6
                    style={{
                      fontWeight: "300",
                      fontSize: "12px",
                      margin: "0px",
                    }}
                  >
                    Today Water Drink
                  </h6>
                </div>
              </div>
            </Item>
          </motion.div>
        </Grid>
        <Grid item xs={2} sm={2} md={3} key={1}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            <Item sx={{ background: "#fae5e5" }}>
              <div>
                <div
                  style={{
                    paddingTop: "40px",
                    paddingBottom: "40px",
                    display: "block",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "700",
                      fontSize: "24px",
                      margin: "0px",
                    }}
                  >
                    73 Mins
                  </h3>
                  <h6
                    style={{
                      fontWeight: "300",
                      fontSize: "12px",
                      margin: "0px",
                    }}
                  >
                    Gym Time
                  </h6>
                </div>
              </div>
            </Item>
          </motion.div>
        </Grid>
        <Grid item xs={2} sm={2} md={3} key={2}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            {" "}
            <Item sx={{ background: "rgb(255, 247, 205)" }}>
              <div>
                <div
                  style={{
                    paddingTop: "40px",
                    paddingBottom: "40px",
                    display: "block",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "700",
                      fontSize: "24px",
                      margin: "0px",
                    }}
                  >
                    4.7 Days
                  </h3>
                  <h6
                    style={{
                      fontWeight: "300",
                      fontSize: "12px",
                      margin: "0px",
                    }}
                  >
                    Gym/D Week
                  </h6>
                </div>
              </div>
            </Item>
          </motion.div>
        </Grid>
        <Grid item xs={2} sm={2} md={3} key={3}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            {" "}
            <Item sx={{ background: "#e5fae5" }}>
              <div>
                <div
                  style={{
                    paddingTop: "40px",
                    paddingBottom: "40px",
                    display: "block",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "700",
                      fontSize: "24px",
                      margin: "0px",
                    }}
                  >
                    38 Sec
                  </h3>
                  <h6
                    style={{
                      fontWeight: "300",
                      fontSize: "12px",
                      margin: "0px",
                    }}
                  >
                    Avg Rest
                  </h6>
                </div>
              </div>
            </Item>
          </motion.div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} key={4}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
          >
            {" "}
            <Item>
              <div
                style={{
                  padding: "1px 8px 8px",
                  fontSize: "18px",
                  fontWeight: "300",
                  textAlign: "left",
                  color: "black",
                  fontFamily: "sans-serif",
                }}
              >
                Exercise Category
              </div>
              <Divider />
              <div style={{ marginTop: "16px" }}>
                <CChart
                  type="doughnut"
                  data={{
                    labels: ["Chest Day", "Back Day", "Legs Day", "Cardio Day"],
                    datasets: [
                      {
                        backgroundColor: [
                          "#d40000",
                          "#dc3232",
                          "#e56666",
                          "#ed9999",
                        ],
                        data: [40, 20, 80, 10],
                      },
                    ],
                  }}
                />
              </div>
            </Item>
          </motion.div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} key={5}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
          >
            {" "}
            <Item>
              <div
                style={{
                  padding: "1px 8px 8px",
                  fontSize: "18px",
                  fontWeight: "300",
                  textAlign: "left",
                  color: "black",
                  fontFamily: "sans-serif",
                }}
              >
                KPI
              </div>
              <Divider />
              <div style={{ marginTop: "16px" }}>
                <CChart
                  type="radar"
                  data={{
                    labels: [
                      "Diet",
                      "Water Intake",
                      "Sleep",
                      "Cardio",
                      "Power Left",
                      "Swim",
                      "Running",
                    ],
                    datasets: [
                      {
                        label: "Weekly Performance",
                        backgroundColor: "#fae5e5",
                        borderColor: "#d40000",
                        pointBackgroundColor: "#6a0000",
                        pointBorderColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "#6a0000",
                        data: [28, 48, 40, 19, 96, 27, 100],
                      },
                    ],
                  }}
                />
              </div>
            </Item>
          </motion.div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} key={6}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
          >
            <Item>
              <div
                style={{
                  padding: "1px 8px 8px",
                  fontSize: "18px",
                  fontWeight: "300",
                  textAlign: "left",
                  color: "black",
                  fontFamily: "sans-serif",
                }}
              >
                Exercise Category
              </div>
              <Divider />
              <div style={{ marginTop: "16px" }}>
                <CChart
                  type="polarArea"
                  data={{
                    labels: [
                      "Chest Day",
                      "Back Day",
                      "Legs Day",
                      "Cardio",
                      "Other",
                    ],
                    datasets: [
                      {
                        data: [11, 16, 7, 3, 14],
                        backgroundColor: [
                          "#d40000",
                          "#dc3232",
                          "#e56666",
                          "#ed9999",
                        ],
                      },
                    ],
                  }}
                />
              </div>
            </Item>
          </motion.div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} key={7}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
          >
            <Item>
              <div
                style={{
                  padding: "1px 8px 8px",
                  fontSize: "18px",
                  fontWeight: "300",
                  textAlign: "left",
                  color: "black",
                  fontFamily: "sans-serif",
                }}
              >
                Minutes In Gym
              </div>
              <Divider />
              <div style={{ marginTop: "16px" }}>
                <CChart
                  type="bar"
                  data={{
                    labels: [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                    ],
                    datasets: [
                      {
                        label: "Minutes/Month",
                        backgroundColor: "#d40000",
                        data: [720, 687, 560, 843, 590, 713, 670, 257, 879],
                      },
                    ],
                  }}
                  labels="months"
                />
              </div>
            </Item>
          </motion.div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} key={8}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
          >
            {" "}
            <Item>
              <div
                style={{
                  padding: "1px 8px 8px",
                  fontSize: "18px",
                  fontWeight: "300",
                  textAlign: "left",
                  color: "black",
                  fontFamily: "sans-serif",
                }}
              >
                TBD
              </div>
              <Divider />
              <div style={{ marginTop: "16px" }}>
                <CChart
                  type="scatter"
                  data={{
                    datasets: [
                      {
                        label: "Scatter Dataset",
                        data: [
                          {
                            x: -10,
                            y: 0,
                          },
                          {
                            x: 0,
                            y: 10,
                          },
                          {
                            x: 5,
                            y: 4,
                          },
                          {
                            x: 7,
                            y: 1,
                          },
                          {
                            x: 2,
                            y: -7,
                          },
                          {
                            x: -1,
                            y: -2,
                          },
                          {
                            x: -3,
                            y: -9,
                          },
                          {
                            x: -5,
                            y: -4,
                          },
                          {
                            x: -7,
                            y: -1,
                          },
                          {
                            x: -2,
                            y: -7,
                          },
                          {
                            x: -1,
                            y: -2,
                          },
                          {
                            x: -3,
                            y: -9,
                          },
                          {
                            x: 0.5,
                            y: 5.5,
                          },
                        ],
                        backgroundColor: "rgb(255, 99, 132)",
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      x: {
                        type: "linear",
                        position: "bottom",
                      },
                    },
                  }}
                />
              </div>
            </Item>
          </motion.div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} key={9}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
          >
            {" "}
            <Item>
              <div
                style={{
                  padding: "1px 8px 8px",
                  fontSize: "18px",
                  fontWeight: "300",
                  textAlign: "left",
                  color: "black",
                  fontFamily: "sans-serif",
                }}
              >
                Average KG
              </div>
              <Divider />
              <div style={{ marginTop: "16px" }}>
                <CChart
                  type="line"
                  data={{
                    labels: [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                    ],
                    datasets: [
                      {
                        label: "KG/Months",
                        backgroundColor: "#d40000",
                        borderColor: "#d40000",
                        pointBackgroundColor: "#d40000",
                        pointBorderColor: "#fff",
                        data: [68, 65, 62, 72, 68, 65, 64, 63, 65],
                      },
                    ],
                  }}
                />
              </div>
            </Item>
          </motion.div>
        </Grid>
      </Grid>
    </div> */
  );
}

export default Dashbaord;
