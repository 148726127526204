import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { db } from "../../config/firebase";
import { collection, query, getDocs, where, orderBy } from "firebase/firestore";

export const firebase_GetNotifications = createAsyncThunk(
  "notifications/firebase_GetNotifications",
  async (_, ThunkAPI) => {
    try {
      const state = ThunkAPI.getState().firebaseAuth;
      const notifications = [];
      // Fetch all exercises to match exerciseDone in exercises
      const exercisesSnapshot = await getDocs(
        query(collection(db, "exercises"))
      );

      // Create a map of exercise IDs to exercise names
      const exerciseNameMap = {};
      exercisesSnapshot.forEach((doc) => {
        exerciseNameMap[doc.id] = doc.data().exerciseName;
      });

      // Fetch workout documents
      const workoutDocs = await getDocs(
        query(
          collection(db, "workouts"),
          where("uid", "==", state.user.uid),
          orderBy("date", "desc")
        )
      );

      const exercisesPromises = workoutDocs.docs.map(async (workoutDoc) => {
        const exerciseSnapshot = await getDocs(
          collection(db, "workouts", workoutDoc.id, "exercises")
        );

        // Map the exercises data for this workout and add exercise names
        const exercises = exerciseSnapshot.docs.map((exerciseDoc) => {
          const exerciseId = exerciseDoc.data().exerciseDone.id;
          const exerciseName = exerciseNameMap[exerciseId];
          return {
            exerciseId: exerciseId,
            exerciseName: exerciseName,
            ...exerciseDoc.data(),
          };
        });

        // Assuming you want to associate the exercises with the workout data
        return {
          workoutId: workoutDoc.id,
          exercises: exercises,
          ...workoutDoc.data(),
        };
      });

      // Wait for all promises to resolve
      const workoutExercises = await Promise.all(exercisesPromises);

      // workoutExercises now contains exercise names along with workout data
      //////////////////////////////////
      // Create an object to store exercise data
      const exerciseData = {};

      // Iterate through workout documents
      workoutExercises.forEach((workout) => {
        // Iterate through exercises in the workout
        workout.exercises.forEach((exercise) => {
          const exerciseId = exercise.exerciseId;
          const kgs = exercise.kg; // Array of strings
          const reps = exercise.reps; // Array of strings

          // Ensure the exercise data object is initialized
          if (!exerciseData[exerciseId]) {
            exerciseData[exerciseId] = {
              name: exercise.exerciseName,
              dates: [],
              totalKg: [], // Represents the total weight (kg) lifted for all sets
              avgKg: [], // Represents the average weight (kg) lifted per set in this session
              maxKg: -Infinity, // Initialize maxKg to negative infinity (across all sessions)
              maxKgSession: [], // Represents the maximum weight (kg) lifted in a set for each session
              sumReps: [],
              totalVolume: [],
              intensity: [],
              lastKg: 0, // Initialize lastKg to 0
            };
          }

          // Parse the array of strings to floats and integers
          const kgsFloat = kgs.map((kg) => parseFloat(kg));
          const repsInt = reps.map((rep) => parseInt(rep));

          exerciseData[exerciseId].dates.push(
            workout.date.toDate().toLocaleDateString()
          );

          // Calculate and add "Sum" kg for each session (total weight lifted for all sets)
          const sumKg = kgsFloat.reduce((sum, kg) => {
            // Update maxKg if a new maximum is encountered (across all sessions)
            if (kg > exerciseData[exerciseId].maxKg) {
              exerciseData[exerciseId].maxKg = kg;
            }
            return sum + kg;
          }, 0);

          exerciseData[exerciseId].totalKg.push(sumKg);

          // Calculate and add "Average" kg per set for this session
          const numSets = kgsFloat.length;
          const avgKgSession = sumKg / numSets;
          exerciseData[exerciseId].avgKg.push(avgKgSession);

          // Calculate and add "Maximum" kg in a set for this session
          const maxKgSession = Math.max(...kgsFloat);
          exerciseData[exerciseId].maxKgSession.push(maxKgSession);

          // Calculate and add "Sum" reps for each session
          const sumReps = repsInt.reduce((sum, reps) => sum + reps, 0);
          exerciseData[exerciseId].sumReps.push(sumReps);

          // Calculate and add total volume for each session (sum of kg * sum of reps)
          const totalVolume = sumKg * sumReps;
          exerciseData[exerciseId].totalVolume.push(totalVolume);

          // Calculate and add intensity (percentage of modified one-rep max)
          // Modified one-rep max is the max kg ever done for the exercise + 30%
          const oneRepMax = exerciseData[exerciseId].maxKg * 1.3;
          const intensity = (totalVolume / oneRepMax) * 100;
          exerciseData[exerciseId].intensity.push(intensity);
          exerciseData[exerciseId].lastKg =
            exerciseData[exerciseId].maxKgSession[0];
        });
      });
      Object.keys(exerciseData).forEach((exercise) => {
        exerciseData[exercise].name = exerciseData[exercise].name;
        exerciseData[exercise].dates = exerciseData[exercise].dates.reverse();
        exerciseData[exercise].totalKg =
          exerciseData[exercise].totalKg.reverse();
        exerciseData[exercise].avgKg = exerciseData[exercise].avgKg.reverse();
        exerciseData[exercise].maxKgSession =
          exerciseData[exercise].maxKgSession.reverse();
        exerciseData[exercise].sumReps =
          exerciseData[exercise].sumReps.reverse();
        exerciseData[exercise].totalVolume =
          exerciseData[exercise].totalVolume.reverse();
        exerciseData[exercise].intensity =
          exerciseData[exercise].intensity.reverse();
        exerciseData[exercise].lastKg = exerciseData[exercise].lastKg;
        exerciseData[exercise].maxKg = exerciseData[exercise].maxKg;
      });
      // Log the data for visualization
      console.log(exerciseData);

      ////////////////
      const querySnapshotNotifications = await getDocs(
        query(
          collection(db, "notifications"),
          orderBy("created_at", "desc"),
          where("uid", "==", state.user.uid)
        )
      );
      for await (const notification of querySnapshotNotifications.docs) {
        notifications.push(notification.data());
      }
      return { notifications: notifications, exmaple: exerciseData };
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    status: "idle",
    notifications: [],
    example: [],
    msg: "",
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.notifications = [];
      state.msg = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_GetNotifications.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_GetNotifications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.notifications = action.payload.notifications;
        state.example = action.payload.exmaple;
      })
      .addCase(firebase_GetNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = notificationsSlice.actions;

export default notificationsSlice.reducer;
