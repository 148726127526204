import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../config/firebase";

export const firebase_resetPassword = createAsyncThunk(
  "signinSlice/firebase_Signin",
  async (_, ThunkAPI) => {
    const state = ThunkAPI.getState().resetPassword;
    try {
      await sendPasswordResetEmail(auth, state.email);
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);
export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    status: "idle",
    email: "",
    msg: "",
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.email = "";
      state.msg = "";
    },
    setEmail: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.status = "idle";
      state.email = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_resetPassword.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_resetPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.status = "idle";
        state.email = "";
        state.msg = "The password reset link has been sent to your email";
      })
      .addCase(firebase_resetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
        state.email = "";
        state.password = "";
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset, setEmail } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
