import React from "react";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
/* import { motion } from "framer-motion";
 */
const ItemPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  borderRadius: "10px",
  display: "flex",
  background:
    theme.palette.mode === "dark"
      ? "rgba(30,30,30,0.8)"
      : "rgba(255,255,255,0.8)",
  backdropFilter: "blur(3px)",
  boxShadow:
    theme.palette.mode === "dark"
      ? null
      : "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
}));

export default function Item({ children, sx, index }) {
  return (
    /*   <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
         delay: index ? index : 1 / 10, 
      }}
    > */
    <ItemPaper sx={sx}>{children}</ItemPaper>
    /*  </motion.div> */
  );
}
