import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { db } from "../../config/firebase";
import { collection, doc, getDoc, query, getDocs } from "firebase/firestore";

export const firebase_GetWorkoutHistoryDetails = createAsyncThunk(
  "workoutHistorySlice/firebase_GetWorkoutHistoryDetails",
  async (workoutId, ThunkAPI) => {
    try {
      const [
        querySnapshotHistoryDetails,
        querySnapshotExercisesNames,
        querySnapshotExercises,
      ] = await Promise.all([
        getDoc(doc(db, "workouts", workoutId)),
        getDocs(query(collection(db, "exercises"))),
        getDocs(query(collection(db, "workouts", workoutId, "exercises"))),
      ]);

      const querySnapshotDayName = await getDoc(
        querySnapshotHistoryDetails.data()["dayId"]
      );

      let exercises = [];
      for await (const exe of querySnapshotExercises.docs) {
        const exeName =
          querySnapshotExercisesNames.docs[
            querySnapshotExercisesNames.docs.findIndex(
              (exeName) => exeName.id === exe.data()["exerciseDone"].id
            )
          ];

        exercises.push({
          ...exe.data(),
          exerciseName: exeName.data()["exerciseName"],
        });
      }

      return {
        ...querySnapshotDayName.data(),
        ...querySnapshotHistoryDetails.data(),
        exercises: exercises.sort(function (a, b) {
          return a.order - b.order;
        }),
      };
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);

export const workoutHistorySlice = createSlice({
  name: "workoutHistory",
  initialState: {
    status: "idle",
    workoutHistoryDetails: [],
    msg: "",
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.workoutHistoryDetails = [];
      state.msg = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_GetWorkoutHistoryDetails.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_GetWorkoutHistoryDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.workoutHistoryDetails = action.payload;
      })
      .addCase(firebase_GetWorkoutHistoryDetails.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = workoutHistorySlice.actions;

export default workoutHistorySlice.reducer;
