// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import {
  CACHE_SIZE_UNLIMITED,
  Timestamp,
  doc,
  persistentLocalCache,
  persistentMultipleTabManager,
  setDoc,
} from "firebase/firestore";
import { initializeFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBUaWcj-NXPHmGj9wOa4rNWzKv9R6RYrk4",
  authDomain: "adrelien-fit.firebaseapp.com",
  databaseURL: "https://adrelien-fit-default-rtdb.firebaseio.com",
  projectId: "adrelien-fit",
  storageBucket: "adrelien-fit.appspot.com",
  messagingSenderId: "289860926313",
  appId: "1:289860926313:web:57bdd73bb7c6ba242e9bf8",
  measurementId: "G-TYMC7RSR0B",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache(
    /*settings*/ {
      tabManager: persistentMultipleTabManager(),
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    }
  ),
});
export const messaging = getMessaging(app);

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BD-NG0lcXxlKM0PVOlCktUntt4Cp_0WxWPt3AoVAI0ooPg2LfbSMB03FaO2HJVqYWhVkWZWudEsneCzeizIXI8Q",
  })
    .then(async (currentToken) => {
      if (currentToken) {
        // Perform any other neccessary action with the token
        await setDoc(doc(db, "fcmTokens", currentToken), {
          fcmToken: currentToken,
          uid: auth.currentUser.uid,
          lastUsed: Timestamp.now(),
        });
      } else {
        // Show permission request UI
        Notification.requestPermission();

        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
