import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Button, Divider, TextField } from "@mui/material";
import { setEmail, reset } from "./resetPasswordSlice";
import Item from "../../components/itemPaper/Item";
import Stack from "@mui/material/Stack";
import logo from "../../resources/images/adrelien.png";
import { useSelector, useDispatch } from "react-redux";
import { firebase_resetPassword } from "./resetPasswordSlice";

function ResetPassword(props) {
  const resetPasswordState = useSelector((state) => state.resetPassword);
  const dispatch = useDispatch();
  useEffect(() => {
    // I'll run when the component is mounted
    // Setting the initial state for this component
    return () => {
      dispatch(reset());
      // all the clean-ups related to this component
      // I'll run when the component is unmounted
    };
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Stack>
        {resetPasswordState.msg !== "" ? (
          <Item
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "25px",
            }}
          >
            {resetPasswordState.msg}
          </Item>
        ) : null}

        <Item sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          <img alt="Adrelien" src={logo} style={{ margin: "28px" }} />

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "10px",
            }}
            autoComplete="false"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(firebase_resetPassword());
            }}
          >
            <TextField
              required={true}
              label="E-mail"
              type="email"
              value={resetPasswordState.email}
              onChange={(e) => {
                dispatch(setEmail(e.target.value));
              }}
            />

            <Button type="submit" variant="outlined">
              Reset Password
            </Button>
          </form>
          <Divider />
          <div>
            You already have an account?{" "}
            <Link to={"../signin"} style={{ color: "inherit" }}>
              Sign In
            </Link>
          </div>
        </Item>
      </Stack>
    </div>
  );
}

export default ResetPassword;
