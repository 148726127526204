import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { db } from "../../config/firebase";
import {
  collection,
  query,
  getDocs,
  Timestamp,
  getDoc,
  doc,
  addDoc,
  updateDoc,
  orderBy,
} from "firebase/firestore";

export const firebase_GetWorkoutExercises = createAsyncThunk(
  "workoutProgressSlice/firebase_GetWorkoutExercise",
  async (payload, ThunkAPI) => {
    try {
      const exercises = [];

      const [querySnapshotExercisesNames, querySnapshotExercises] =
        await Promise.all([
          getDocs(query(collection(db, "exercises"))),
          getDocs(
            query(
              collection(
                db,
                "programs",
                payload[0],
                "Days",
                payload[1],
                "exercises"
              )
            )
          ),
        ]);

      for await (const exe of querySnapshotExercises.docs) {
        const exeName =
          querySnapshotExercisesNames.docs[
            querySnapshotExercisesNames.docs.findIndex(
              (exeName) => exeName.id === exe.data()["exercise"].id
            )
          ];

        exercises.push({
          ...exe.data(),
          exerciseName: exeName.data()["exerciseName"],
          img: exeName.data()["img"],
        });
      }

      return exercises.sort(function (a, b) {
        return a.order - b.order;
      });
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);
export const firebase_SetExercisesLogs = createAsyncThunk(
  "workoutProgressSlice/firebase_SetExercisesLogs",
  async (payload, ThunkAPI) => {
    const state = ThunkAPI.getState().firebaseAuth;
    const exerciseLogs = ThunkAPI.getState().workoutProgress.exerciseLogs;
    if (exerciseLogs.length === 0) {
      return true;
    }
    try {
      let order = 1;
      // Workout session metadata
      const data = {
        date: Timestamp.now(),
        dayId: (
          await getDoc(doc(db, "programs", payload[0], "Days", payload[1]))
        ).ref,
        dayName: (
          await getDoc(doc(db, "programs", payload[0], "Days", payload[1]))
        ).data().dayName,
        duration: { hrs: payload[2], mins: payload[3], secs: payload[4] },
        programId: (await getDoc(doc(db, "programs", payload[0]))).ref,
        uid: state.user.uid,
      };
      // Create workout
      const logId = await addDoc(collection(db, "workouts"), data);
      // for eahc exe we extract data and add it
      for await (const exe of exerciseLogs) {
        const kg = [];
        for await (const subarray of exe["logs"]) {
          kg.push(subarray[0]);
        }
        const reps = [];
        for await (const subarray of exe["logs"]) {
          reps.push(subarray[1]);
        }

        await addDoc(collection(db, "workouts", logId.id, "exercises"), {
          exerciseDone: exe["exercise"],
          order: order,
          kg: kg.reverse(),
          reps: reps.reverse(),
        });
        order = order + 1;
      }

      // Skipping a day

      const [querySnapshotDays, querySnapshotDayNumber] = await Promise.all([
        getDocs(
          query(
            collection(
              db,
              "programs",
              state.userProfile.currentProgram.id,
              "Days"
            ),
            orderBy("dayNumber", "asc")
          )
        ),
        (await getDoc(state.userProfile.currentDay)).data()["dayNumber"],
      ]);

      await updateDoc(doc(db, "users", state.user.uid), {
        currentDay:
          querySnapshotDays.docs[
            querySnapshotDayNumber % querySnapshotDays.docs.length
          ].ref,
      });
      return true;
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);

export const workoutProgressSlice = createSlice({
  name: "workoutProgress",
  initialState: {
    status: "idle",
    setStatus: "idle",
    workoutExercises: [],
    currentExercise: null,
    exerciseLogs: [],
    msg: "",
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.setStatus = "idle";
      state.workoutExercises = [];
      state.exerciseLogs = [];
      state.currentExercise = null;
      state.msg = "";
    },
    pushExerciseLogs: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload["logs"] != null) {
        state.exerciseLogs.push(action.payload);
        state.currentExercise = state.workoutExercises.shift();
      }
      if (action.payload["logs"] === null) {
        state.currentExercise = state.workoutExercises.shift();
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_GetWorkoutExercises.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_GetWorkoutExercises.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.workoutExercises = action.payload;
        state.currentExercise = state.workoutExercises.shift();
      })
      .addCase(firebase_GetWorkoutExercises.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
      })
      .addCase(firebase_SetExercisesLogs.pending, (state, action) => {
        state.setStatus = "loading";
        state.msg = "";
      })
      .addCase(firebase_SetExercisesLogs.fulfilled, (state, action) => {
        state.setStatus = "succeeded";
      })
      .addCase(firebase_SetExercisesLogs.rejected, (state, action) => {
        state.setStatus = "failed";
        state.msg = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset, pushExerciseLogs } = workoutProgressSlice.actions;

export default workoutProgressSlice.reducer;
