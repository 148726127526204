import { React } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../navbar/navbar";
import Divider from "@mui/material/Divider";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
} from "@mui/material";
import Item from "../../components/itemPaper/Item";
import { FitnessCenterOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firebase_GetWorkoutHistoryDetails } from "./workoutHistorySlice";
import { CChart } from "@coreui/react-chartjs";
import AppBar from "../../components/appBar/appBar";
import DialogSlide from "../../components/dialogSlide/dialogSlide";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

function WorkoutHistory({ workoutHistoryID, openStatus, onClose }) {
  const workoutHistoryState = useSelector((state) => state.workoutHistory);
  const params = useParams();
  //const workoutHistoryID = params.workoutHistoryID;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(firebase_GetWorkoutHistoryDetails(workoutHistoryID));
  }, [dispatch, workoutHistoryID]);
  return (
    <div>
      <DialogSlide openStatus={openStatus}>
        {workoutHistoryState.status === "succeeded" ? (
          <div>
            <AppBar
              title={workoutHistoryState.workoutHistoryDetails.dayName + " Day"}
              subtitle={new Date(
                workoutHistoryState.workoutHistoryDetails.date.seconds * 1000
              ).toLocaleDateString("en-US")}
            />
            <Item
              sx={{
                marginBottom: "28px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#d40000",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                      color: "#fff",
                    }}
                  >
                    <FitnessCenterOutlined />
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: "12px", fontWeight: "100" }}>
                      {new Date(
                        workoutHistoryState.workoutHistoryDetails.date.seconds *
                          1000
                      ).toLocaleDateString("en-US")}
                    </div>
                    <div style={{ fontSize: "18px", fontWeight: "300" }}>
                      {workoutHistoryState.workoutHistoryDetails.dayName}
                    </div>
                  </div>
                </div>
                <div style={{ fontSize: "18px", fontWeight: "300" }}>
                  {workoutHistoryState.workoutHistoryDetails.duration.hrs +
                    ":" +
                    workoutHistoryState.workoutHistoryDetails.duration.mins +
                    ":" +
                    workoutHistoryState.workoutHistoryDetails.duration.secs}
                </div>
              </div>
            </Item>
            {workoutHistoryState.workoutHistoryDetails?.exercises?.map(
              (exe, index) => {
                return (
                  <Item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "45px",
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        textAlign: "left",
                        marginBottom: "16px",
                      }}
                    >
                      {exe["exerciseName"]}
                    </div>
                    <Divider sx={{ marginBottom: "16px" }} />
                    <CChart
                      type="line"
                      height={250}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false, // Allow chart resizing

                        tooltip: {
                          enabled: false, // <-- this option disables tooltips
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false, // Hide x-axis grid lines
                            },
                            title: {
                              display: true,
                              text: "Sets / KG",
                            },

                            ticks: {
                              callback: function (value) {
                                return exe["reps"][value] + " Reps"; // Add 'units' to Y-axis labels
                              },
                            },
                          },
                          y: {
                            grid: {
                              display: false, // Hide x-axis grid lines
                            },
                            title: {
                              display: false,
                              text: "KG",
                            },
                            suggestedMin: Math.min(...exe["kg"]) - 0.8, // Minimum value with buffer
                            suggestedMax: Math.max(...exe["kg"]) + 0.8, // Maximum value with buffer
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                      data={{
                        labels: exe["reps"],
                        datasets: [
                          {
                            label: exe["exerciseName"],
                            data: exe["kg"],
                            pointRadius: 0, // Hide dots
                            tension: 0.4, // Adjust the curve tension (0.4 for a moderate curve)
                            borderColor: "#d40000", // Set the line color to #d40000
                            backgroundColor: "#d40000", // Fill color
                          },
                          /* 
                                ******** SHOULD BE LAST EXE COMPARE ********
                                { 
                                  label: "My Second dataset",
                                  backgroundColor: "rgba(151, 187, 205, 0.2)",
                                  borderColor: "rgba(151, 187, 205, 1)",
                                  pointBackgroundColor:
                                    "rgba(151, 187, 205, 1)",
                                  pointBorderColor: "#fff",
                                  data: [
                                    50, 12, 28, 29, 7, 25, 12, 70, 60, 29, 7,
                                    25, 12, 70, 60, 29, 7, 25, 12, 70, 60, 29,
                                    7, 25, 12, 70, 60, 29, 7, 25, 12, 70, 60,
                                  ],
                                }, */
                        ],
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          marginTop: "16px",
                          minWidth: "100px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "100",
                            flex: 1,
                          }}
                        >
                          Total Weight Lifted
                        </div>
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "300",
                            flex: 1,
                          }}
                        >
                          {exe["kg"].reduce(
                            (a, b) => parseFloat(a) + parseFloat(b),
                            0
                          ) + " KG"}
                        </div>
                      </div>
                      <div
                        style={{
                          textAlign: "left",
                          marginTop: "16px",
                          minWidth: "100px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "100",
                            flex: 1,
                          }}
                        >
                          Max Weight Lifted
                        </div>
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "300",
                            flex: 1,
                          }}
                        >
                          {Math.max.apply(Math, exe["kg"]) + " KG"}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "16px",
                        marginTop: "16px",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          marginTop: "16px",
                          minWidth: "100px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "100",
                            flex: 1,
                          }}
                        >
                          Total Sets
                        </div>
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "300",
                            flex: 1,
                          }}
                        >
                          {exe["reps"].length + " Sets"}
                        </div>
                      </div>
                      <div
                        style={{
                          textAlign: "left",
                          marginTop: "16px",
                          minWidth: "100px",
                        }}
                      >
                        <div style={{ fontSize: "12px", fontWeight: "100" }}>
                          Total Reps
                        </div>
                        <div style={{ fontSize: "18px", fontWeight: "300" }}>
                          {exe["reps"].reduce(
                            (a, b) => parseFloat(a) + parseFloat(b),
                            0
                          ) + " Reps"}
                        </div>
                      </div>
                    </div>
                  </Item>
                );
              }
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div>
          <BottomNavigation
            sx={{
              zIndex: "1200",
              position: "fixed",
              bottom: 30,
              left: "5%",
              right: "5%",
              borderRadius: "200px",
              "@media (min-width: 1000px)": {
                marginLeft: "30%",
                marginRight: "30%",
              },
            }}
          >
            <BottomNavigationAction
              onClick={onClose}
              icon={<ClearOutlinedIcon />}
            />
          </BottomNavigation>
        </div>
      </DialogSlide>
    </div>
  );
}

export default WorkoutHistory;
