import { React, useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../navbar/navbar";
import Divider from "@mui/material/Divider";

import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Stack from "@mui/material/Stack";
import { CircularProgress } from "@mui/material";
import Item from "../../components/itemPaper/Item";
import { FitnessCenterOutlined } from "@mui/icons-material";
import { firebase_SkipDay } from "./workoutSlice";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "../../components/appBar/appBar";
import WorkoutHistory from "./workoutHistory";

function Workout(props) {
  const [openSkip, setOpenSkip] = useState(false);
  const [openStart, setOpenStart] = useState(false);
  const [workoutHistoryID, setWorkoutHistoryID] = useState(null);
  const [openStatus, setOpenStatus] = useState(false);
  const workoutState = useSelector((state) => state.workout);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <WorkoutHistory
        workoutHistoryID={workoutHistoryID}
        openStatus={openStatus}
        onClose={() => setOpenStatus(false)}
      />
      <Dialog
        open={openSkip}
        onClose={() => setOpenSkip(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Skip Workout Day"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your are about to skip a workout day
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack direction="row">
            <Button
              onClick={() => {
                setOpenSkip(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                setOpenSkip(false);
                dispatch(firebase_SkipDay());
              }}
            >
              Skip
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openStart}
        onClose={() => setOpenStart(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Start Workout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your are about to start a workout
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack direction="row">
            <Button
              onClick={() => {
                setOpenStart(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                setOpenSkip(false);
                navigate(
                  "/workout/start/" + workoutState.currentDay["id"].path
                );
              }}
            >
              Start
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Navbar />
      <Helmet>
        <title>Workout</title>
      </Helmet>
      {workoutState.status === "succeeded" ? (
        <Stack>
          <AppBar title={"Workout"} subtitle={"View & Start"} />
          <div>
            <Item
              sx={{
                marginBottom: "28px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#d40000",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                      color: "#fff",
                    }}
                  >
                    <FitnessCenterOutlined />
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: "12px", fontWeight: "100" }}>
                      Start Workout
                    </div>
                    <div style={{ fontSize: "18px", fontWeight: "300" }}>
                      {workoutState.currentDay?.dayName}
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                {workoutState.currentDay?.exercises?.map((exe, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key={index}
                    >
                      <div style={{ textAlign: "left" }}>
                        {exe["exerciseName"]}
                      </div>
                      <div style={{ textAlign: "right" }}>
                        {exe["reps"].map((rep, index) => {
                          if (index === exe["reps"].length - 1) {
                            return rep;
                          } else {
                            return rep + " / ";
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "16px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenSkip(true);
                  }}
                >
                  Skip
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenStart(true);
                  }}
                >
                  Start Workout
                </Button>
              </div>
            </Item>
            {workoutState.workoutHistory.length !== 0 ? (
              workoutState.workoutHistory.map((row, index) => (
                <Item
                  key={index}
                  sx={{
                    // Stack should provide some margin 12px coming from app.js need to look into it
                    marginTop: "12px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    onClick={() => {
                      setWorkoutHistoryID(row.id);
                      setOpenStatus(true);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          background: "#d40000",
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                          color: "#fff",
                        }}
                      >
                        <FitnessCenterOutlined />
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <div style={{ fontSize: "12px", fontWeight: "100" }}>
                          {row.dayName}
                        </div>
                        <div style={{ fontSize: "18px", fontWeight: "300" }}>
                          {row.duration.hrs +
                            ":" +
                            row.duration.mins +
                            ":" +
                            row.duration.secs}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {new Date(row.date.seconds * 1000).toLocaleDateString(
                          "en-US"
                        )}
                      </div>

                      <ArrowForwardIosSharpIcon
                        sx={{
                          cursor: "pointer",
                          height: "15px",
                        }}
                      />
                    </div>
                  </div>
                </Item>
              ))
            ) : (
              <Item
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "16px",
                }}
              >
                <Item
                  sx={{
                    margin: "-16px",
                    background: "#d40000",
                    marginRight: "16px",
                  }}
                />
                No Workout History Found
              </Item>
            )}
          </div>
        </Stack>
      ) : workoutState.status === "failed" ? (
        <Stack>
          <AppBar title={"Workout"} subtitle={"View & Start"} />
          <Item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "12px",
            }}
          >
            <Item
              sx={{
                margin: "-16px",
                background: "#d40000",
                marginRight: "16px",
              }}
            />
            No Workout Selected
          </Item>
        </Stack>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default Workout;
