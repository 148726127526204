import React from "react";
import { Helmet } from "react-helmet-async";

import { Button, CardHeader, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Navbar from "../navbar/navbar";
import Item from "../../components/itemPaper/Item";

import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../config/firebaseAuthSlice";
import Stack from "@mui/material/Stack";

import { motion } from "framer-motion";
import AppBar from "../../components/appBar/appBar";

function Profile(props) {
  const profileState = useSelector((state) => state.firebaseAuth);
  const dispatch = useDispatch();
  return (
    <div>
      <Navbar />
      <Helmet>
        <title>Profile</title>
      </Helmet>

      <Stack>
        <AppBar title={"Profile"} />
        <motion.div>
          <Item
            sx={{
              marginBottom: "28px",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardHeader
                  sx={{ textAlign: "left", padding: "0" }}
                  avatar={
                    <Avatar>
                      {profileState.user.email.substring(0, 1).toUpperCase()}
                    </Avatar>
                  }
                />
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontSize: "12px", fontWeight: "100" }}>
                    {profileState.user.email}
                  </div>
                  <div style={{ fontSize: "18px", fontWeight: "300" }}>
                    {"@" + profileState.userProfile.username}
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div style={{ marginTop: "16px", marginBottom: "16px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>Username</div>
                <div>{"@" + profileState.userProfile.username}</div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>Status</div>
                <div>
                  {profileState.user.emailVerified
                    ? "Verified"
                    : "Not Verified"}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>Email</div>
                <div>{profileState.user.email}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>Account Created</div>
                <div>{profileState.user.metadata.creationTime}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>Last Login</div>
                <div>{profileState.user.metadata.lastSignInTime}</div>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginTop: "16px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(logout());
                }}
              >
                Sign Out
              </Button>
            </div>
          </Item>
        </motion.div>
      </Stack>
    </div>
  );
}

export default Profile;
