import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import { db } from "../../config/firebase";
import {
  collection,
  doc,
  query,
  getDocs,
  where,
  setDoc,
} from "firebase/firestore";

export const firebase_Signup = createAsyncThunk(
  "signupSlice/firebase_Signup",
  async (_, ThunkAPI) => {
    const state = ThunkAPI.getState().signup;
    try {
      const regex = /^[a-zA-Z0-9]{3,12}$/;

      if (regex.test(state.username)) {
        if (state.password === state.passwordConfirm) {
          const querySnapshotUsername = await getDocs(
            query(
              collection(db, "users"),
              where("username", "==", state.username)
            )
          );
          if (querySnapshotUsername.empty) {
            await createUserWithEmailAndPassword(
              auth,
              state.email,
              state.password
            )
              .then(async () => {
                await setDoc(doc(db, "users", auth.currentUser.uid), {
                  uid: auth.currentUser.uid,
                  username: state.username,
                  email: state.email,
                });
              })
              .then(() => {
                sendEmailVerification(auth.currentUser);
              });
          } else {
            return ThunkAPI.rejectWithValue("Username not available");
          }
        } else {
          return ThunkAPI.rejectWithValue("Passwords does not match");
        }
      } else {
        return ThunkAPI.rejectWithValue(
          "Username must be between 3-12 letters, alphabets and numbers"
        );
      }
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);

export const usernameAvailable = createAsyncThunk(
  "signupSlice/usernameAvailable",
  async (_, ThunkAPI) => {
    const state = ThunkAPI.getState().signup;
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("username", "==", state.username))
      );
      if (querySnapshot.empty) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);

export const signupSlice = createSlice({
  name: "signup",
  initialState: {
    status: "idle",
    email: "",
    password: "",
    passwordConfirm: "",
    username: "",
    usernameAvailable: true,
    msg: "",
  },
  reducers: {
    reset: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = "idle";
      state.email = "";
      state.password = "";
      state.passwordConfirm = "";
      state.username = "";
      state.usernameAvailable = true;
      state.msg = "";
    },
    setEmail: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.status = "idle";
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.status = "idle";
      state.password = action.payload;
    },
    setPasswordConfirm: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.status = "idle";
      state.passwordConfirm = action.payload;
    },
    setUsername: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.status = "idle";
      state.username = action.payload.toLowerCase();
    },
  },
  extraReducers(builder) {
    builder
      .addCase(firebase_Signup.pending, (state, action) => {
        state.status = "loading";
        state.msg = "";
      })
      .addCase(firebase_Signup.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(firebase_Signup.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
        state.email = "";
        state.password = "";
        state.passwordConfirm = "";
        state.username = "";
      })
      .addCase(usernameAvailable.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(usernameAvailable.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.usernameAvailable = action.payload;
      })
      .addCase(usernameAvailable.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset, setEmail, setPassword, setPasswordConfirm, setUsername } =
  signupSlice.actions;

export default signupSlice.reducer;
