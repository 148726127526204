import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import navbarReducer from "../features/navbar/navbarSlice";
import signupReducer from "../features/auth/signupSlice";
import signinReducer from "../features/auth/signinSlice";
import firebaseAuthReducer from "../config/firebaseAuthSlice";
import resetPasswordReducer from "../features/auth/resetPasswordSlice";
import programsReducer from "../features/programs/programsSlice";
import programDetailsReducer from "../features/programs/programDetailsSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import workoutReducer from "../features/workout/workoutSlice";
import workoutHistoryReducer from "../features/workout/workoutHistorySlice";
import workoutProgressReducer from "../features/workout/workoutProgressSlice";
export default configureStore({
  reducer: {
    counter: counterReducer,
    navbar: navbarReducer,
    signup: signupReducer,
    signin: signinReducer,
    firebaseAuth: firebaseAuthReducer,
    resetPassword: resetPasswordReducer,
    programs: programsReducer,
    programDetails: programDetailsReducer,
    notifications: notificationsReducer,
    workout: workoutReducer,
    workoutHistory: workoutHistoryReducer,
    workoutProgress: workoutProgressReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
