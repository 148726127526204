import Snackbar from "@mui/material/Snackbar";

export default function BreakPill({ open, sx, icon, title, body, action }) {
  return (
    <Snackbar
      action={action}
      sx={{
        ...sx,
      }}
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#d40000",
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
                color: "#fff",
              }}
            >
              {icon}
            </div>
            <div style={{ textAlign: "left" }}>
              <div style={{ fontSize: "12px", fontWeight: "100" }}>{title}</div>
              <div style={{ fontSize: "18px", fontWeight: "300" }}>{body}</div>
            </div>
          </div>
        </div>
      }
    />
  );
}
