import React from "react";
import AppBar from "../appBar/appBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Slide from "@mui/material/Slide";
import { Divider } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DialogSlide({
  sx,
  openStatus,
  onClose,
  title,
  subtitle,
  children,
}) {
  return (
    <Dialog
      fullScreen
      open={openStatus}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        ...sx,
        "@media (min-width: 1000px)": {
          paddingLeft: "30%",
          paddingRight: "30%",
        },
      }}
    >
      {title && (
        <AppBar title={title} subtitle={subtitle}>
          <Divider />
        </AppBar>
      )}
      <Stack sx={{ paddingBottom: "100px" }}>{children}</Stack>
    </Dialog>
  );
}
