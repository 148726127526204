import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Button, Divider, TextField } from "@mui/material";
import { firebase_Signin, setEmail, setPassword, reset } from "./signinSlice";
import Item from "../../components/itemPaper/Item";
import Stack from "@mui/material/Stack";
import logo from "../../resources/images/adrelien.png";
import { useSelector, useDispatch } from "react-redux";

function Signin(props) {
  const signinState = useSelector((state) => state.signin);
  const dispatch = useDispatch();
  useEffect(() => {
    // I'll run when the component is mounted
    // Setting the initial state for this component
    return () => {
      dispatch(reset());

      // all the clean-ups related to this component
      // I'll run when the component is unmounted
    };
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <Stack>
        {signinState.msg !== "" ? (
          <Item
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "25px",
            }}
          >
            {signinState.msg}
          </Item>
        ) : null}

        <Item sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          <img alt="Adrelien" src={logo} style={{ margin: "28px" }} />

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "10px",
            }}
            autoComplete="false"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(firebase_Signin());
            }}
          >
            <TextField
              required={true}
              label="E-mail"
              type="email"
              value={signinState.email}
              onChange={(e) => {
                dispatch(setEmail(e.target.value));
              }}
            />
            <TextField
              required={true}
              label="Password"
              type="password"
              value={signinState.password}
              onChange={(e) => {
                dispatch(setPassword(e.target.value));
              }}
            />
            <div
              style={{
                marginTop: "-10px",
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              Forgot your password?{" "}
              <Link to={"../resetpassword"} style={{ color: "inherit" }}>
                Reset Password
              </Link>
            </div>
            <Button
              disabled={signinState.state === "loading" ? true : false}
              type="submit"
              variant="outlined"
            >
              Sign In
            </Button>
          </form>
          <Divider />
          <div>
            You don't have an account yet?{" "}
            <Link to={"../signup"} style={{ color: "inherit" }}>
              Sign Up
            </Link>
          </div>
        </Item>
      </Stack>
    </div>
  );
}

export default Signin;
