import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
  Divider,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Item from "../../components/itemPaper/Item";
import {
  firebase_GetProgramDetails,
  firebase_SetProgramDetails,
  reset,
} from "./programDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AppBar from "../../components/appBar/appBar";
import DialogSlide from "../../components/dialogSlide/dialogSlide";
function ProgramDetails({ programID, programName, openStatus, onClose }) {
  const params = useParams();
  /*  const programID = params.programID;
  const programName = params.programName; */
  const programDetailsState = useSelector((state) => state.programDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(firebase_GetProgramDetails(programID));
    return () => {
      dispatch(reset());
    };
  }, [dispatch, programID]);

  useEffect(() => {
    if (programDetailsState.setStatus === "succeeded") {
      navigate("/programs");
    }
  }, [programDetailsState.setStatus, navigate]);

  return (
    <div>
      <DialogSlide openStatus={openStatus}>
        {programDetailsState.status === "succeeded" &&
        (programDetailsState.setStatus === "succeeded" ||
          programDetailsState.setStatus === "idle") ? (
          <div>
            <AppBar title={programName} />

            {programDetailsState.programDetails.length !== 0
              ? programDetailsState.programDetails.map((row, index) => (
                  <Item
                    sx={{ flexDirection: "column", marginBottom: "45px" }}
                    key={index}
                  >
                    <div
                      style={{
                        padding: "1px 8px 8px",
                        fontSize: "18px",
                        fontWeight: "300",
                        textAlign: "left",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Day {row.dayNumber} - {row.dayName}
                    </div>
                    <Divider />
                    <div style={{ marginTop: "16px" }}>
                      {row["exercises"].map((exe, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            key={index}
                          >
                            <div style={{ textAlign: "left" }}>
                              {exe["exerciseName"]}
                            </div>
                            <div style={{ textAlign: "left" }}>
                              {exe["reps"].map((rep, index) => {
                                if (index === exe["reps"].length - 1) {
                                  return rep;
                                } else {
                                  return rep + " / ";
                                }
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Item>
                ))
              : null}

            <BottomNavigation
              sx={{
                zIndex: "1200",
                position: "fixed",
                bottom: 30,
                left: "5%",
                right: "5%",
                borderRadius: "200px",
                "@media (min-width: 1000px)": {
                  marginLeft: "30%",
                  marginRight: "30%",
                },
              }}
            >
              <BottomNavigationAction
                onClick={onClose}
                icon={<ClearOutlinedIcon />}
              />
              <BottomNavigationAction
                sx={{ color: "#d40000" }}
                onClick={() => {
                  dispatch(firebase_SetProgramDetails(programID));
                }}
                icon={<DoneOutlinedIcon />}
              />
            </BottomNavigation>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {programDetailsState.status != "succeeded" && (
          <div>
            <BottomNavigation
              sx={{
                zIndex: "1200",
                position: "fixed",
                bottom: 30,
                left: "5%",
                right: "5%",
                borderRadius: "200px",
                "@media (min-width: 1000px)": {
                  marginLeft: "30%",
                  marginRight: "30%",
                },
              }}
            >
              <BottomNavigationAction
                onClick={onClose}
                icon={<ClearOutlinedIcon />}
              />
            </BottomNavigation>
          </div>
        )}
      </DialogSlide>
    </div>
  );
}

export default ProgramDetails;
